import React from "react";
import { Switch, Route} from "react-router-dom";

import DealsList from './DealsList'
import DealItem from './DealItem'

export default (props) => {
    const {match, user} = props;

    return(<Switch>
        {/* path: /admins/:destinationID/deals */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <DealsList user={user} {...props }/> }></Route> 
        
        {/* path: /admins/:destinationID/deals/newDeal */ }
        <Route exact path={`${match.path}/newDeal`} render = {(props)=> <DealItem  user={user} {...props}/> }></Route>

        {/* path: /admins/:destinationID/delas/:dealID */ }
        <Route path={`${match.path}/:dealID`} render = {(props)=> <DealItem  user={user} {...props}/> }></Route>
    </Switch>)
}

