import React from "react";
import { Switch, Route} from "react-router-dom";

import GiftCardList from './GiftCardList'
import GiftCardItem from './GiftCardItem'

export default (props) => {
    const {match, user} = props;

    return(<Switch>
        
        <Route exact path={`${match.path}/`} render = {(props)=> <GiftCardList user={user} {...props }/> }></Route> 
        
        
        <Route exact path={`${match.path}/newGiftCard`} render = {(props)=> <GiftCardItem  user={user} {...props}/> }></Route>

        
        <Route path={`${match.path}/:giftcardID`} render = {(props)=> <GiftCardItem  user={user} {...props}/> }></Route>
    </Switch>)
}

