import React, { useState, useEffect, useMemo, Fragment } from 'react';
import 'draft-js/dist/Draft.css';
import RichTextEditor from 'react-rte';



/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";



import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formPaper: {
        width: "100%",
        marginBottom: "15px",
        padding: theme.spacing(2)
    },
    container: {
        padding: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    adornment: {
        marginRight: 0
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    button: {
        margin: theme.spacing(1),
    },

}));



export default ({ handleChange, metaData, lang }) => {

    const classes = useStyles();
    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Strikethrough', style: 'STRIKETHROUGH' },
            { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };
    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...metaData, [lang]: { ...metaData[lang], [name]: value } })
    }
    const descriptionChange = (value) => {
        handleChange({ ...metaData, [lang]: { ...metaData[lang], description: value.toString('html') } })
        setEditorState(value)
    }
    let description
    if (metaData[lang].description) {
        description = RichTextEditor.createValueFromString(metaData[lang].description.toString(), 'html')
    } else {
        description = RichTextEditor.createValueFromString("", 'html')
    }
    const [editorState, setEditorState] = useState(description);

    return (

        
            <Accordion className={classes.formPaper}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{`Web Data ${lang=="hr"? "HR": "EN"}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item container xs={12}>
                        <FormControl fullWidth className={classes.margin}>
                            <InputLabel htmlFor="title">Title</InputLabel>
                            <Input
                                id={`${lang}-title`}
                                value={metaData[lang].title}
                                name="title"
                                onChange={inputChange}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.margin}>
                            <InputLabel htmlFor="subtitle">Subtitle</InputLabel>
                            <Input
                                id={`${lang}-subtitle`}
                                value={metaData[lang].subtitle}
                                name="subtitle"
                                onChange={inputChange}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.margin}>
                            <TextField
                                id={`${lang}-shortdesc`}
                                label="Short description"
                                value={metaData[lang].shortdesc}
                                onChange={inputChange}
                                name="shortdesc"
                                multiline
                                rows={4}

                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.marginTop}>
                            <Typography
                                variant="subtitle1"
                                color="initial"
                                noWrap
                                style={{ color: "rgba(0, 0, 0, 0.54)", marginLeft: "5px", marginBottom: "5px" }}
                            >
                                Description
                            </Typography>
                            <RichTextEditor value={editorState} onChange={descriptionChange} toolbarConfig={toolbarConfig} />
                        </FormControl>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            )
}

