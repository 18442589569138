import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import moment, { utc } from 'moment-timezone';

import API from './network'
import Loading from './Loading'
import FlashMessage from './FlashMessage'
// Check if user is login

// If false show login form 
// if true call api.iqmpass.com/validatecard/passID/partnerID

export default (props) => {

    const { match } = props;
    const { code } = match.params

    const [user, setUser] = useState(null)
    const [passcard, setPasscard] = useState(null)
    const [order, setOrder] = useState(null)


    const [error, setError] = useState(null)
    const [fetching, setFetching] = useState(true)


    const partnerPassvalidation = async () => {
        try {
            const user = await API.authorise()
            if (!user) return

            const response = await API.validatePass(code);
            const { error, passcard, order } = response


            setError(error)

            setUser(user)
            setPasscard(passcard)
            setOrder(order)

            return true
        } catch (error) {
            return error
        }
    }

    const handleLogin = ()=>{
        setFetching(true)
        partnerPassvalidation().then(response => {
            console.log(response)
            setFetching(false)
        }).catch(e => console.log(e))
    }

    useEffect(() => {
        partnerPassvalidation().then(response => {
            console.log(response)
            setFetching(false)
        }).catch(e => console.log(e))
    }, [])


    if (fetching) return <Loading fullScreen={true} />

    if (!user)
        return (<LoginForm {...props} handleLogin={handleLogin} />)

    if (user && passcard && order)
        return (<Fragment>
            <IQMPassView {...props} passcard={passcard} />
            <IncludedView  {...props} order={order} user={user} />
        </Fragment>)


    return <ErrorView error={error} />
}

function formatDateDisplay(date) {
    date = new Date(date)
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear(),
      hour = '' + (date.getHours()),
      min = '' + date.getMinutes();



    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (min.length < 2)
      min = '0' + min;



    // console.log("FORMAT DATE", `${day}.${month}.${year}., ${hour}:${min}`)
    return `${day}.${month}.${year}., ${hour}:${min}`;
  }


const IQMPassView = (props) => {
    const { passcard } = props
    let { startDate, endDate } = passcard

    // startDate = moment.utc(startDate)
    // endDate = moment.utc(endDate)
    startDate = moment.utc(startDate).format('YYYY-MM-DDTHH:mm') + "+02:00"
    endDate = moment.utc(endDate).format('YYYY-MM-DDTHH:mm')+ "+02:00"
    
    

    // startDate = moment.utc(startDate)
    // endDate = moment.utc(endDate)


    console.log(startDate, endDate, moment)
    let isValidPass = { success: false, message: `IQM Pass ${passcard.code} is not valid` }
    const today = moment.utc()

    console.log(moment.utc(startDate), moment.utc(endDate), moment.utc())
    
    if (moment.utc().isSameOrAfter(moment.utc(startDate)) && moment.utc().isSameOrBefore(moment.utc(endDate))) {
        isValidPass = { success: true, message: `IQM Pass ${passcard.code} is valid` }
    } else if (moment.utc().isSameOrAfter(moment.utc(endDate))) {
        isValidPass = { success: false, message: `IQM Pass ${passcard.code} expired on ${moment(endDate).format('DD.MM.YYYY HH:mm')}` }
    } else if (moment.utc().isSameOrBefore(moment.utc(startDate))) {
        isValidPass = { success: false, message: `IQM Pass ${passcard.code} will be active on ${moment(startDate).format('DD.MM.YYYY HH:mm')}` }
    } else {
        isValidPass = { success: false, message: `Someting is wrong validating IQM Pass ${passcard.code} ` }
    }



    return (
        <Fragment>
            <FlashMessage {...isValidPass} />
            <div className="IQMPassView">
                <div className="container">
                    <h1>IQM PASS</h1>
                    <p>{`CODE: ${passcard.code}`}</p>
                    <p>{`TYPE: ${passcard.passType}`}</p>
                    <p>{`START DATE: ${moment(startDate).format('DD.MM.YYYY HH:mm')}`}</p>
                    <p>{`END DATE: ${moment(endDate).format('DD.MM.YYYY HH:mm')}`}</p>
                    <p>{`DURATION: ${passcard.duration / 24} days (${passcard.duration}h)`}</p>
                </div>
            </div>
        </Fragment>
    )
}


const IncludedView = (props) => {
    const { order, user } = props
    const { included } = order

    const DealsItemList = included.map(deal => {
        if(user._id !== deal.partnerID) return false

        const lang = 'hr' // Todo handle different lang 
        


        const dealData = deal.dealData.find(data => data.lang === lang)
        const discount = deal.discount.find(discount => {
            if (discount.userType !== 'any') return false
            if ((discount.currency === 'HRK' && lang === 'hr') || (discount.currency === 'EUR' && lang === 'en')) return discount
            else return false
        })
        const price = deal.price.find(price => {
            if (price.userType !== 'any') return false
            if ((price.currency === 'HRK' && lang === 'hr') || (price.currency === 'EUR' && lang === 'en')) return price
            else return false
          })




        const curencyMap = {
            "HRK": "kn",
            "EUR": "€"
        }
        const discountvalue = discount.discountType === 'cash' ? `-${discount.value}${curencyMap[discount.currency]}` :
            (discount.discountType === 'trade' ? `-${discount.value}%` : "GIFT")


        // Todo: Show regular price ??
        return (
            <div className="deals__item" name={dealData.title} title={dealData.title}>
                <div className="deals__discount">{discountvalue}</div>
                <h2 className="deals__h1">{dealData.title}</h2>
                <div className="deals__text">{dealData.description}</div>

                {/* <div className="card card--large">
                    <div className="card__img">
                        <div className="card__discount">{discountvalue}</div>
                    </div>
                    <div className="card__row card__row--white">
                        <h2 className="card__h1">{dealData.title}</h2>
                        <div className="card__text">{dealData.description}</div>
                    </div>
                </div>
                <div className="deals__mask" /> */}
            </div>)


    })



    return (
        <div id="deals">
            <div className="container">
                <h1> What is included </h1>
                <div className="deals">
                    {DealsItemList}
                </div>
            </div>
        </div>
    )

}


const LoginForm = (props) => {

    const [loginData, setLoginData] = useState({ email: '', password: '' })

    const handleChange = (event) => {
        const { name, value } = event.target
        setLoginData({ ...loginData, [name]: value })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const user = await API.login({
                email: loginData.email,
                password: loginData.password
            });
            props.handleLogin(user);

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className="modal section" id="signin">
            <div className="modalWrap">
                <div className="modalBox-wrap">
                    <a className="modalBox__logo" href="index">
                        {/* <img src={Logo} alt="IQM Destination" /> */}
                    </a>
                    <div className="modalBox">
                        <div className="modalBox__form">
                            <form className="form form--signin" onSubmit={handleSubmit}>
                                {/* <div className="form__message"><span>Invalid login, please try again</span>
                    <button className="form__close">
                      <svg width="15" height="16" viewbox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.731 2l12 12m-12 0l12-12" stroke="#1F0330" stroke-width="3.064" fill="none"
                          fill-rule="evenodd"></path>
                      </svg>
                    </button>
                  </div> */}
                                <div className="form__title">Sign in</div>
                                <div className="form__item">
                                    <div className="form__icon form__icon--email"></div>
                                    <input className="email" type="email" name="email" placeholder="Email" required="required" onChange={handleChange} />
                                    {/* <div className="form__error">Invalid email</div> */}
                                </div>
                                <div className="form__item">
                                    <div className="form__icon form__icon--password"></div>
                                    <input className="password" type="password" name="password" placeholder="Password"
                                        required="required" onChange={handleChange} />
                                    {/* <div className="form__error">Invalid password</div> */}
                                </div>
                                <div className="form__item">
                                    <input className="btn btn--blue btn--fill" id="signinBtn" value="Sign in" type="submit" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const ErrorView = (props) => {
    const { error } = props
    if(error)
       return <h1> {error.message} </h1>
       else 
    return null
}