import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


/* Dashboard pages */
import Partners from './pages/partner'
import Deals from './pages/deals'

// Pages
import Destinations from './pages/destinations'
import Notifications from './pages/notifications'
import Orders from './pages/orders'
import Passes from './pages/passes'
import Overview from './pages/overview'
import Widgets from './pages/widgets'
import Giftcards from './pages/giftcards'
import Itinerary from './pages/itinerary'
import TripPlanner from './pages/trip-planner'


import Config from './pages/config'

// Components
import SideNav from './components/SideNav'
import NavBar from './components/NavBar'
import Loading from './components/Loading'


import API from './network'

export default (props) => {
    const {user, handleLogout} = props;

    return(<Fragment>
        <NavBar {...props} handleLogout={handleLogout}/>
            <Switch>
                <Route exact path={`${props.match.path}/`} render = {(props)=> <Destinations user={user} {...props }/> }></Route>
                <Route exact path={`${props.match.path}/config`} render = {(props)=> <Config user={user} {...props }/> }></Route>
                <Route path={`${props.match.path}/:destinationID`} render = {(props)=> <DestinationDashboard  user={user} {...props}/> }></Route>
            </Switch>
    </Fragment>)
}



const DestinationDashboard = (props)=> {
    const {user, match} = props;
    const {destinationID} = match.params;

    const [destination, setDestination] = useState({})
    const [fetching, setFetching] = useState(false)

    useEffect(()=>{
        setFetching(true)
        API.destination.getDestination(destinationID).then((destination)=>{
            setDestination(destination)
            setFetching(false)
        }).catch(e => console.log(e))
    },[])

    if(fetching) return <Loading fullScreen={true} />
    return(<Fragment>
            <SideNav  {...props} destination={destination}/>
            <Switch>
                <Route exact path={`${props.match.path}`} render = {(props)=> <Overview user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/orders`} render = {(props)=> <Orders user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/partners`} render = {(props)=> <Partners user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/deals`} render = {(props)=> <Deals user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/passes`} render = {(props)=> <Passes user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/widgets`} render = {(props)=> <Widgets user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/giftcards`} render = {(props)=> <Giftcards user={user} {...props}/> }></Route>
                <Route path={`${props.match.path}/notifications`} render = {(props)=> <Notifications  {...props}/> }></Route>
                <Route path={`${props.match.path}/itinerary`} render = {(props)=> <Itinerary  {...props}/> }></Route>
                <Route path={`${props.match.path}/trip-planner`} render = {(props)=> <TripPlanner  {...props}/> }></Route>
            </Switch>
    </Fragment>)


}





