import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import Loading from '../../components/Loading'
import API from '../../network'




export default (props) => {

  const { match, user, history} = props;

  const {destinationID} = match.params;

  const [deals, setDeals] = useState([])
  const [partners, setPartners] = useState([])
  const [fetching, setFetching] = useState(false)


  useEffect(()=>{
    setFetching(true)
    API.deal.getDeals(destinationID).then((deals)=>{
        
        setDeals(deals)
        API.partner.getPartners(destinationID).then(partners => {
          setPartners(partners)
        
          setFetching(false)


        }).catch(e=>console.log(e))
    }).catch(e=>console.log(e))
  },[])

  
  const DealItems = deals.map((deal)=> {

    const getPartnerName = ()=>{
      const partner = partners.find(partner => partner._id === deal.partnerID)
      return partner? partner.slug : ''
    }

    const categoriesList = (category)=>{
      let categoryString = ''
      for( let i = 0; i< category.length; i++)
        categoryString += ((i+1)==category.length)? category[i] : category[i] + '\\';
      return categoryString
    }
    const priceHRK = deal.price.find(p => p.currency === 'HRK')
      const priceEUR = deal.price.find(p => p.currency === 'EUR')

    return(  
      <tr className="deal-link" onClick = {()=>history.push(`${match.url}/${deal._id}`)}>
        <td>{deal.slug}</td>
        <td>{deal.name}</td>
        <td>{getPartnerName(partners)}</td>
        <td>{categoriesList(deal.categories)}</td>
        <td>{`${deal.discount[0].value}%`}</td>
        <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
        <td>{deal.status}</td>
      </tr>
    )

  })

  if(fetching) return <Loading />
  return (
    <div className="dashboard section">
      <div className="main show" id="deals">
        <div className="dashboard__main">
          <div className="dashboard__row">
            {/* <Search /> */}

            <div className="dashboard__btn">
              <div className="btn btn--fill btn--green" id="dealNew" > <Link to={`${match.url}/newDeal`}>Add New IQM Deal </Link></div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <thead>
                <tr className="deal-link" data-href="url:/">
                  <th>ID</th>
                  <th>Name</th>
                  <th>Partner Name</th>
                  <th>Type</th>
                  <th>Discount</th>
                  <th>Price (Eur/Hrk)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {DealItems}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>)
}