import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";




import PassList from './PassList'
import PassItem from './PassItem'


export default (props) => {
    const {match, user} = props;


    return(<Switch>
        {/* path: /admins/:destinationID/pass */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <PassList user={user} {...props }/> }></Route> 
        
        {/* path: /admins/:destinationID/pass/newPass */ }
        <Route exact path={`${match.path}/newPass`} render = {(props)=> <PassItem  user={user} {...props}/> }></Route>

        {/* path: /admins/:destinationID/partners/:partnerID */ }
        <Route path={`${match.path}/:passID`} render = {(props)=> <PassItem  user={user} {...props}/> }></Route>
    </Switch>)
}