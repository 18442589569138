import React, { useState, useEffect } from 'react';


import Loading from '../../components/Loading'
import API from '../../network'

import toSlug from '../../helpers/toSlug'


export default (props) => {

    const { match, history } = props;
    const { destinationID, giftcardID } = match.params;

    const [name, setName] = useState("")
    const [discount, setDiscount] = useState()
    const [quantity, setQuantity] = useState()
    const [code, setCode] = useState("")
    const [giftType, setGiftType] = useState("ALL")

    useEffect(() => {
        if (giftcardID) {
            API.giftcard.getGiftCard(destinationID, giftcardID).then(giftcard => {
                setName(giftcard.name)
                setDiscount(giftcard.discount)
                setQuantity(giftcard.quantity)
                setCode(giftcard.code)
                setGiftType(giftcard.type)
            })
        }
        setFetching(false)
    }, [])


    const handleSubmit = async () => {
        setFetching(true)

        const giftcard = {
            name,
            discount,
            quantity,
            code,
            giftType
        }
        try {
            if (giftcardID) {
              await API.giftcard.updateGiftCard(destinationID, giftcardID, giftcard)
                // TODO: FLASH MESSAGE
                alert("Save success")
           }
           else {
              const giftcardRes = await API.giftcard.createGiftCard(destinationID, giftcard)
              if(giftcardRes._id)
                history.push(match.url.replace("/newGiftCard", ""))
           }
           
          } catch (error) {
            console.log(error)
          }

        setFetching(false)
    }


    const handleDelete = async () => {
        setFetching(true)


        setFetching(false)
    }

    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="giftpassItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="giftcardBack" onClick={() => history.goBack()} >Back</div>
                        </div>
                        <div className="dashboard__title">{giftcardID ? "GiftCard Edit" : "GiftCard Create"}</div>
                    </div>

                    <div className="form-wrap">
                        <form className="form">

                            <div className="form__item">
                                <label htmlFor="name">Gift Card Name*</label>
                                <input className="name" type="text" name="name" placeholder="Enter Gift Card name" required="required" value={name} onChange={e => setName(e.target.value)} />
                            </div>

                            <div className="form__item">
                                <label htmlFor="code">Gift Card Promo code*</label>
                                <input className="code" type="text" name="code" placeholder="Enter code" required="required" value={code} onChange={e => setCode(e.target.value)} />
                            </div>

                            <div className="form__item">
                                <label htmlFor="discount">Discount %</label>
                                <input className="discount" type="text" name="discount" placeholder="Enter discount" required="required" value={discount} onChange={e => setDiscount(e.target.value)} />
                            </div>

                            <div className="form__field">
                                <div className="formWrap">
                                    <div>
                                        <div className="form__item">
                                            <label htmlFor="quantity">Quantity*</label>
                                            <input className="quantity" type="text" value={quantity} name="quantity" placeholder="Enter quantity" required="required" onChange={e => setQuantity(e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form__item">
                                            <label htmlFor="price-type">Gift Card Type</label>
                                            <select name="price-type" required="required" onChange={e => setGiftType(e.target.value)} value={giftType}>
                                                <option value="ALL">ALL</option>
                                                <option value="SINGLE">SINGLE</option>
                                                <option value="COUPLE">COUPLE</option>
                                                <option value="TEAM">TEAM</option>
                                                <option value="CHILDREN">CHILDREN</option>
                                                <option value="FAMILY">FAMILY</option>
                                                
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="form__item">
                                <div className="button-wrap">
                                    <div className="btn btn--red btn--fill" id="giftcardDelete" onClick={handleDelete}>Delete Pass</div>
                                    <input className="btn btn--green btn--fill" value="Save" onClick={handleSubmit} />
                                </div>
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </div>
    );
}

