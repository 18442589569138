import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";



import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formPaper: {
        width: "100%",
        marginBottom: "15px",
        padding: theme.spacing(2)
    },
    container: {
        padding: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    adornment: {
        marginRight: 0
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    button: {
        margin: theme.spacing(1),
    },

}));

export default ({ acceptType, file, setFile }) => {

    const classes = useStyles();

    const [error, setError] = useState();

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        minHeight: '125px'
    };
    const focusedStyle = {
        borderColor: '#2196f3'
    };
    const acceptStyle = {
        borderColor: '#00e676'
    };
    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const contentStyle = {
        margin: 'auto',
        fontSize: '20px',
        textAlign: "center"
    }
    const contentSpanStyle = {
        fontSize: '16px',
        marginTop: "5px"
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: acceptType,
        maxFiles: 1
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setFile(acceptedFiles[0])
            setError(null);
        } else if (fileRejections.length) {
            setFile(null);
            setError(fileRejections[0])
        }
    }, [
        acceptedFiles,
        fileRejections
    ])

    const RejectFileView = fileRejections.map(({ file, errors }) => {
        return (
            <div style={{ color: "red" }}>
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>
                {file.path} - {file.size} bytes


            </div>
        )
    });

    return <Paper className={classes.formPaper}>
        <Grid item container xs={12}>

            {file ? <AcceptFileView file={file} setFile={setFile} />
                :

                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p style={contentStyle}>Upload Cover Image <br />
                        <span style={contentSpanStyle}>Only *.jpeg and *.png images will be accepted </span>
                    </p>
                    {error ? <aside>
                        {RejectFileView}
                    </aside> : null
                    }
                </div>
            }
        </Grid>
    </Paper>

}



const AcceptFileView = ({ file, setFile, coverPage }) => {

    var preview = "";
    if (file.constructor == File) {
        preview = URL.createObjectURL(file);
    } else if (file.constructor == Object) {
        preview = `https://assets.iqmpass.com/${file.path}`;
    }
    return <Grid item container xs={12} >
        <Grid item container xs={8}>
            <img
                src={preview}
                srcSet={preview}
                loading="lazy"
                alt="preview"
                style={{ height: 350, margin: 15 }} />
        </Grid>
        <Grid item container xs={4}>
            <Box component="div"
                style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }} >
                <Button variant="outlined" color="secondary" onClick={() => {setFile(null) }}>
                    Remove
                </Button>
            </Box>
        </Grid>
    </Grid>

}




    //      <div><pre>{JSON.stringify(file, null, 2)}</pre></div>   

    //     {/* <Typography style={{
    //         fontSize: 20,
    //         width: "100%",
    //         margin: 8
    //     }}
    //         align="center"
    //         color="text.secondary"
    //         gutterBottom>
    //         <Box style={{
    //             height: 150,
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //         }}>
    //             {coverPage ? <img
    //                 src={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format`}
    //                 srcSet={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format&dpr=2 2x`}
    //                 loading="lazy"
    //                 alt={coverPage.name}
    //                 style={{ width: 75, margin: 15 }}
    //             /> :
    //                 <PictureAsPdfIcon />}
    //             <Box sx={{
    //                 whiteSpace: "nowrap",
    //                 overflow: "hidden",
    //                 textOverflow: "ellipsis",
    //                 maxWidth: "calc(100% - 160px)",
    //                 textAlign: "start",
    //                 flexGrow: 2
    //             }} >
    //                 {file.constructor == File ? file.name : file.filename}
    //             </Box>
    //             <Button sx={{
    //                 alignSelf: "flex-end",
    //                 flexGrow: 1,
    //                 maxWidth: 120
    //             }}
    //                 onClick={() => { setFile(null) }}
    //                 variant="outlined" startIcon={<DeleteIcon />}>
    //                 Delete
    //             </Button>
    //         </Box>
    //     </Typography> */}
    // </Paper>
