import React, { Fragment, useState, useEffect } from "react";

import Loading from '../../components/Loading'
import API from '../../network'
import toSlug from '../../helpers/toSlug'

export default (props) => {

  
  const { match, history } = props;
  const { destinationID } = match.params;
  // To simplify state management parnter object is splited to match, Mongo PartnerSchema

  /* According to Schema partnerData is the list of data for different languages, but for simplicity of UI
      and speed only HR and ENG will be implemented - Hardcode! 
  */

  const [credentials, setCredentials] = useState({
    _id: '',
    slug: '',
    name: '',
    email: '',
    password: '',
    destinationID: destinationID
  })

  // Todo: Add some type checking
  let partnerData = {
    'lang': '',
    'title': '',
    'description': '',
    'phone': '',
    'website': ''
  }
  const [partnerDataEN, setPartnerDataEN] = useState({ ...partnerData, 'lang': 'en' })
  const [partnerDataHR, setPartnerDataHR] = useState({ ...partnerData, 'lang': 'hr' })

  const [address, setAddress] = useState({
    string: '',
    city: '',
    postal_code: '',
    country_code: ''
  })

  // Todo: Follow up with Schema 
  const [socialNetworks, setSocialNetworks] = useState({
    facebook: '',
    google: '',
    twitter: '',
    instagram: '',
    tripadvisor: '',
    linkedin: ''
  })


  const [deals, setDeals] = useState([])
  const [fetching, setFetching] = useState(false)

  

  const loadPartnerAndDeals = async (destinationID, partnerID) => {
    try {
      const partner = await API.partner.getPartner(destinationID, partnerID)
    
      setCredentials({
        _id: partner._id,
        slug: partner.slug,
        name: partner.name,
        email: partner.email,
        password: partner.password,
        destinationID: partner.destinationID
      })

      setAddress({ ...address, ...partner.address })

      if (partner.partnerData) {
        partner.partnerData.forEach(data => {
          if (data.lang === 'hr') setPartnerDataHR(data)
          if (data.lang === 'en') setPartnerDataEN(data)
        })
      }

      if (partner.socialNetworks) {
        let socials = {}
        partner.socialNetworks.forEach(data => {
          socials[data.social] = data.url
        })
        setSocialNetworks(socials)
      }

      const deals = await API.deal.getPartnerDeals(destinationID, partnerID)
      setDeals(deals)
      
    } catch (error) {
      console.log(error)
    }
  }

  if (match.params.partnerID) {
    useEffect(() => {
      setFetching(true)
      loadPartnerAndDeals(match.params.destinationID,match.params.partnerID).then(() => setFetching(false))
        .catch(error => console.log(error))
    }, [])
  }


  const handleSubmit = async () => {

    
    var re = new RegExp("^(http|https)://", "i");
    if(partnerDataEN.website && !re.test(partnerDataEN.website)) {
        alert("Website url should start with http:// or https://")

        return
    }
    if(partnerDataHR.website && !re.test(partnerDataHR.website)) {
        alert("Website url should start with http:// or https://")
        
        return
    }

    setFetching(true)
    let socials = []
    for (var social in socialNetworks)
      socials.push({ social: social, url: socialNetworks[social] })

    const partner = {
      ...credentials,
      slug: toSlug(credentials.name),
      address,
      partnerData: [partnerDataEN, partnerDataHR],
      socialNetworks: socials
    }
    const {destinationID, partnerID} = match.params
    if (partnerID) {
      const partnerRes = await API.partner.updatePartner(destinationID, partnerID, partner)
      alert("Save success")
    } 
    else{
      const partnerRes = await API.partner.createPartner(destinationID, partner)
      
      if(partnerRes._id) {
        const {url} = match
        history.push(url.replace("/newPartner", ""))
      }

    } 

    setFetching(false)
  }

  const handleDelete = async () => {
    const {destinationID, partnerID} = match.params
    setFetching(true)
    // Todo: Handle with dialogg
    const res = await API.partner.deletePartner(destinationID, partnerID)
    console.log(res)
    if(res.ok === 1 ) {
      const {url} = match
      history.push(url.replace(`/${partnerID}`, ""))
  } else {

  }
    setFetching(false)
  }

  if(fetching) return <Loading />
  return (
    <div className="dashboard section">
      <div className="main show" id="partnersItem">
        <div className="dashboard__main">
          <div className="dashboard__row">
            <div className="dashboard__btn">
              <div className="btn btn--fill btn--yellow" id="partnersBack" onClick={() => props.history.goBack()}>Back</div>
            </div>
            <div className="dashboard__title">{match.params.partnerID ? "Partner Edit" : "Partner Create"}</div>
          </div>


          <div className="form-wrap">
            <form className="form">
              <CredentialsForm credentials={credentials} handleChange={setCredentials} />

              <div className="form__item">
                <div className="formWrap">
                  <PartnerDataForm parentData={partnerDataHR} handleChange={setPartnerDataHR} />
                  <PartnerDataForm parentData={partnerDataEN} handleChange={setPartnerDataEN} />
                </div>
              </div>

              <div className="form__item">
                <div className="formWrap">
                  <AddressForm address={address} handleChange={setAddress} />
                  <SocialsForm socialNetworks={socialNetworks} handleChange={setSocialNetworks} />
                </div>
              </div>

              <div className="form__item form__item--mb50">
                <ControlsForm partnerID={match.params.partnerID} handleSubmit={handleSubmit} handleDelete={handleDelete} />
              </div>
              <PartnerDeals deals={deals} {...props} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


/* SUB COMPONENTS */


const CredentialsForm = ({ handleChange, credentials }) => {

  const inputChange = (event) => {
    const { name, value } = event.target
    handleChange({ ...credentials, [name]: value })
  }

  return (<Fragment><div className="form__item">
    <label htmlFor="slug">Partner Name*</label>
    <input className="name" type="text" name="name" placeholder="Enter partner name" required="required" value={credentials.name} onChange={inputChange} />
  </div>
    <div className="form__item">
      <label htmlFor="email">Partner Email*</label>
      <input className="email" type="text" name="email" placeholder="Enter partner email" required="required" value={credentials.email} onChange={inputChange} />
    </div>
    <div className="form__item">
      <label htmlFor="password">Partner Password*</label>
      <input className="password" type="password" name="password" placeholder="Enter partner password" required="required" value={credentials.password} onChange={inputChange} />
    </div>
  </Fragment>)
}

const PartnerDataForm = ({ handleChange, parentData }) => {
  const inputChange = (event) => {
    const { name, value } = event.target
    handleChange({ ...parentData, [name]: value })
  }

  return (<div>
    <div className="form__field-title">{`Details (${parentData.lang.toUpperCase()})`}</div>
    <div className="form__field">

      <div className="form__item">
        <label htmlFor="title">Partner title*</label>
        <input className="title" type="text" name="title" placeholder="Enter partner title" required="required" value={parentData.title} onChange={inputChange} />
      </div>

      <div className="form__item">
        <label htmlFor="description">Partner description*</label>
        <textarea className="description" cols="1" rows="2" name="description" placeholder="Enter partner description" required="required" value={parentData.description} onChange={inputChange} ></textarea>
      </div>

      <div className="form__item">
        <label htmlFor="phone" onChange={inputChange} >Partner phone*</label>
        <input className="phone" type="text" name="phone" placeholder="Enter partner phone" required="required" value={parentData.phone} onChange={inputChange} />
      </div>

      <div className="form__item">
        <label htmlFor="website">Partner website</label>
        <input className="website" type="text" name="website" placeholder="Enter partner website" value={parentData.website} onChange={inputChange} />
      </div>
    </div>
  </div>)

}

const SocialsForm = ({ handleChange, socialNetworks }) => {
  const inputChange = (event) => {
    const { name, value } = event.target
    handleChange({ ...socialNetworks, [name]: value })
  }

  return (<div>
    <div className="form__field-title">Social Networks</div>
    <div className="form__field">
      <div className="form__item">
        <label htmlFor="google">Google</label>
        <input className="google" type="text" name="google" placeholder="Enter Google link" value={socialNetworks.google} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="instagram">Instagram</label>
        <input className="instagram" type="text" name="instagram" placeholder="Enter Instagram link" value={socialNetworks.instagram} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="facebook">Facebook</label>
        <input className="facebook" type="text" name="facebook" placeholder="Enter Facebook link" value={socialNetworks.facebook} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="twitter">Twitter</label>
        <input className="twitter" type="text" name="twitter" placeholder="Enter Twitter link" value={socialNetworks.twitter} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="tripadvisor">Trip Advisor</label>
        <input className="tripadvisor" type="text" name="tripadvisor" placeholder="Enter Trip Advisor link" value={socialNetworks.tripadvisor} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="linkedin">LinkedIn</label>
        <input className="linkedin" type="text" name="linkedin" placeholder="Enter LinkedIn link" value={socialNetworks.linkedin} onChange={inputChange} />
      </div>
    </div>
  </div>)
}

const AddressForm = ({ handleChange, address }) => {

  const inputChange = (event) => {
    const { name, value } = event.target
    handleChange({ ...address, [name]: value })
  }

  return (<div>
    <div className="form__field-title">Address</div>
    <div className="form__field">
      <div className="form__item">
        <label htmlFor="street">Street</label>
        <input className="street" type="text" name="street" placeholder="Enter street" value={address.street} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="city">City</label>
        <input className="city" type="text" name="city" placeholder="Enter city" value={address.city} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="postal-code">Postal Code</label>
        <input className="postal" type="text" name="postal_code" placeholder="Enter postal code" value={address.postal_code} onChange={inputChange} />
      </div>
      <div className="form__item">
        <label htmlFor="country-code">Country Code</label>
        <input className="country" type="text" name="country_code" placeholder="Enter country code" value={address.country_code} onChange={inputChange} />
      </div>
    </div>
  </div>)
}


const ControlsForm = ({ partnerID, handleSubmit, handleDelete }) => {

  return (<div className="button-wrap">
    {
      partnerID ? <Fragment>
        <div className="btn btn--red btn--fill" id="partnerDelete" onClick={handleDelete} >Delete Partner</div>
        {/* {type="submit"} */}
        <div className="btn btn--green btn--fill" onClick={handleSubmit}> Save </div>
      </Fragment> : <Fragment>
          {/* {type="submit"} */}
          <div className="btn btn--green btn--fill" onClick={handleSubmit}> Create </div>
        </Fragment>
    }

  </div>)
}


const PartnerDeals = ({match, deals}) => {

  console.log(match, deals)
  const DealItems = deals.map((deal)=>{

    const categoriesList = (categories)=>{
      let categoryString = ''
      for( let i = 0; i< categories.length; i++)
        categoryString += ((i+1) === categories.length)? categories[i] : categories[i] + '\\';
      return categoryString
    }

    const priceHRK = deal.price.find(p => p.currency === 'HRK')
    const priceEUR = deal.price.find(p => p.currency === 'EUR')

    return( 
        <tr key ={deal._id} className="deal-link" >
            <td>{deal.slug}</td>
            <td>{deal.name}</td>
            <td>{categoriesList(deal.categories)}</td>
            <td>{`${deal.discount[0].value}%`}</td>
            <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
            <td>{deal.status}</td>
        </tr>)
    })


  return (<div className="form__item form__item--mb50">
    <div className="form__field-title">Partner's Deals</div>
    <div className="table-wrap table-wrap--nopadding">
      <table className="table">
        <thead>
          <tr className="deal-link" data-href="url:/">
            <th>ID</th>
            <th>Name</th>
            <th>Type</th>
            <th>Discount</th>
            <th>Price (Eur/Hrk)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {DealItems}
        </tbody>
      </table>
    </div>
  </div>
  )
}
