import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";




import WidgetList from './WidgetList'
import WidgetItem from './WidgetItem'


export default (props) => {
    const {match, user} = props;


    return(<Switch>
        {/* path: /admins/:destinationID/widgets */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <WidgetList user={user} {...props }/> }></Route> 
        
        {/* path: /admins/:destinationID/widgets/newWidget */ }
        <Route exact path={`${match.path}/newWidget`} render = {(props)=> <WidgetItem  user={user} {...props}/> }></Route>

        {/* path: /admins/:destinationID/widgets/:partnerID */ }
        <Route path={`${match.path}/:widgetID`} render = {(props)=> <WidgetItem  user={user} {...props}/> }></Route>
    </Switch>)
}