import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig

/* **************************************************************************************
 *                                  ADMIN
 ************************************************************************************** */

const authorise = async () => {
    const token = getToken();
    if (!token) return null;

    const options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    try {
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/authorise`, options);

        const { user } = await response.json();

        return user;
    } catch (error) {

        console.error(error);
        return null;
    }

}

const login = async ({ email, password }) => {

    if (!email || !password) throw new Error('Auth.loginAdmin wrong params.')

    const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, password }) // body data type must match "Content-Type" header
    }
    try {

        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/login`, options);
        const { user, token } = await response.json();

        if (token && user) {
            setToken(token)
            return user
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

const logout = () => {
    // Todo: Implement ServerSideFirst
    return setToken(null)
}



export default {
    authorise,
    login,
    logout
}