import React, { useState, useEffect } from 'react';


import Loading from '../../components/Loading'
import API from '../../network'

import toSlug from '../../helpers/toSlug'


export default (props) => {

    const { match, history } = props;
    const { destinationID, orderID } = match.params;

    const [order, setOrder] = useState({});
    const [passcardList, setPasscardList] = useState([])
    const [giftcard, setGiftcard] = useState(null)

    const LoadData = async (destinationID, orderID) => {
        const order = await API.order.getOrder(destinationID, orderID)
        const passcardList = await API.order.getOrderPasscard(destinationID, orderID);

        setOrder(order)
        setPasscardList(passcardList)

        if(order.giftcardID) {
            const giftcard = await API.giftcard.getGiftCard(destinationID, order.giftcardID)
            setGiftcard(giftcard)
        }
            

    }
    useEffect(() => {
        LoadData(destinationID, orderID).then(() => {
            setFetching(false)
        }).catch(e => console.log(e))
    }, [])

    const downloadPasscard = (passcardID)=> {
        API.order.downloadPasscard(destinationID, passcardID) 
    }

    const SectionTitleStyle = {
        fontSize: '20px',
        margin: 'auto',
        marginBottom: '10px',
        paddingBottom: '5px',
        fontWeight: '600',
        //borderBottom: '1px solid #f2b83b'
    }

    const TopBorderStyle = {
        borderTop: '1px solid #f2b83b',
        paddingTop: '10px'
    }

    const [fetching, setFetching] = useState(true)
    if (fetching) return <Loading />



    return (
        <div className="dashboard section">
            <div className="main show" id="giftpassItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="giftcardBack" onClick={() => history.goBack()} >Back</div>
                        </div>
                        {/* <div className="dashboard__title">{giftcardID ? "GiftCard Edit" : "GiftCard Create"}</div> */}
                    </div>

                    <div className="form-wrap">
                        <div className="form__item">
                            <div style={SectionTitleStyle} className="form__field-title">{`Order  ${order._id}`}</div>
                            <div className="form__field">
                                <label style={{ fontSize: "18px" }}>Customer</label>
                                <ul>
                                    <li style={{marginTop: "10px"}}><span><b>Name: </b></span><span>{`${order.customer.CustomerFirstName} ${order.customer.CustomerLastName}`}</span></li>
                                    <li style={{marginTop: "10px"}}><span><b>Email: </b></span><span>{`${order.customer.CustomerEmail}`}</span></li>
                                    {giftcard ? 
                                    <li style={{marginTop: "10px"}}><span><b>Gift: </b></span><span>{`${giftcard.name}`}</span> <br/> <span><i>{`${giftcard.code} (${giftcard.discount}% ${giftcard.giftType})`}</i></span></li>:
                                    <li style={{marginTop: "10px"}}><span><b>Gift: </b></span><span>N/A</span></li>}
                                </ul>
                            </div>
                        </div>
                        <div className="form__item">
                            <PassCardTable passcardList={passcardList} download={downloadPasscard}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const PassCardTable = ({ passcardList, download }) => {

    const PassCardItems = passcardList.map(passcard => {
        return (
            <tr className="passcard-link">
                <td>{passcard.code}</td>
                <td>{passcard.passType}</td>
                <td>{passcard.duration}</td>
                <td><div className="btn btn--green btn--fill" onClick={() => { download(passcard._id)}}>Download</div></td>
            </tr>)
        
    })

    return (<div className="table-wrap">
        <table className="table">
            <thead>
                <tr className="deal-link" data-href="url:/">
                    <th>CODE</th>
                    <th>TYPE</th>
                    <th>DURATION</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {PassCardItems}
            </tbody>
        </table>

    </div>)

}
