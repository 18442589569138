import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig


/* **************************************************************************************
 *                                  ITINERARY
 ************************************************************************************** */
/*
    
*/
const getItineraryList = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getItineraryList`, options);
        return await response.json();
    } catch (error) {
        console.error(error);
        return error;
    }
}

/*
    
*/
const getItinerary = async (destinationID, itineraryID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!itineraryID) throw new Error('itineraryID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, itineraryID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getItinerary`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }
}
/*
*/
const createItinerary = async (destinationID, payload) => {

    const {uploadCoverImage, ...itinerary } = payload;
    const data = new FormData();

    

    data.append('payload', JSON.stringify({ ...itinerary }))
    data.append('destinationID', destinationID)


    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/createItinerary`, options);
        const jsonRes = await response.json();
        console.log(jsonRes)
        return jsonRes
    } catch (error) {

        console.error(error);
        return null;
    }
}


/*
*/
const updateItinerary = async (destinationID, itineraryID, payload) => {
    const { uploadCoverImage, ...itinerary } = payload;
    const data = new FormData();

    data.append('payload', JSON.stringify({ ...itinerary }))
    data.append('destinationID', destinationID)
    data.append('itineraryID', itineraryID)

    

    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateItinerary`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}

/*
*/
const deleteItinerary = async (destinationID, itineraryID) => {

    if (!itineraryID) throw new Error('itineraryID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, itineraryID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deleteItinerary`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }

}





export default {
    getItinerary,
    getItineraryList,
    createItinerary,
    updateItinerary,
    deleteItinerary
}