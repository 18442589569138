import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Loading from '../../components/Loading'
import API from '../../network'


export default (props) => {

  const { match, history, user } = props;
  const { destinationID } = match.params;

  const [passes, setPasses] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    setFetching(true)
    API.pass.getPasses(destinationID).then((deals) => {
      setPasses(deals)
      setFetching(false)
    }).catch(error => console.log(error))
  }, [])


  const PassItems = passes.map((pass) => {

    const priceHRK = pass.price.find(p => p.currency === 'HRK')
    const priceEUR = pass.price.find(p => p.currency === 'EUR')


    return (

      <tr className="pass-link" onClick={() => history.push(`${match.url}/${pass._id}`)}>
        <td>{pass.slug}</td>
        <td>{pass.name}</td>
        <td>{pass.passType}</td>
        <td>{`${pass.duration}h`}</td>
        <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
      </tr>
    )

  })

  if (fetching) return <Loading />
  return (
    <div className="dashboard section">
      <div className="main show" id="pass">
        <div className="dashboard__main">
          <div className="dashboard__row">
            {/* <Search /> */}

            <div className="dashboard__btn">
              <div className="btn btn--fill btn--green" id="passNew"><Link to={`${match.url}/newPass`}>Add New IQM Pass</Link></div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <thead>
                <tr className="pass-link" data-href="url:/">
                  <th>ID</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Duration</th>
                  <th>Price (Eur/Hrk)</th>
                </tr>
              </thead>
              <tbody>
                {PassItems}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}