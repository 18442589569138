import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig


/* **************************************************************************************
 *                                  TRIP-PLANNER
 ************************************************************************************** */
/*
    
*/
const getTripPlannerList = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getTripPlannerList`, options);
        return await response.json();
    } catch (error) {
        console.error("network->tripPlaner", error);
        throw new Error(error);
    }
}

/*
    
*/
const getTripPlanner = async (destinationID, tripPlannerID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!tripPlannerID) throw new Error('tripPlannerID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, tripPlannerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getTripPlanner`, options);
        return await response.json();
    } catch (error) {
        console.error("network->tripPlaner", error);
        throw new Error(error);
    }
}
/*
*/
const createTripPlanner = async (destinationID, payload) => {

    const {uploadCoverImage, ...tripPlanner } = payload;
    const data = new FormData();

    

    data.append('payload', JSON.stringify({ ...tripPlanner }))
    data.append('destinationID', destinationID)


    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/createTripPlanner`, options);
        const jsonRes = await response.json();
        console.log(jsonRes)
        return jsonRes
    } catch (error) {
        console.error("network->tripPlaner", error);
        throw new Error(error);
    }
}


/*
*/
const updateTripPlanner = async (destinationID, tripPlannerID, payload) => {
    const { uploadCoverImage, ...tripPlanner } = payload;
    const data = new FormData();

    data.append('payload', JSON.stringify({ ...tripPlanner }))
    data.append('destinationID', destinationID)
    data.append('tripPlannerID', tripPlannerID)

    

    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateTripPlanner`, options);
        return await response.json();
    } catch (error) {
        console.error("network->tripPlaner", error);
        throw new Error(error);
    }

}

/*
*/
const deleteTripPlanner = async (destinationID, tripPlannerID) => {

    if (!tripPlannerID) throw new Error('tripPlannerID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, tripPlannerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deleteTripPlanner`, options);
        return await response.json();
    } catch (error) {
        console.error("network->tripPlaner", error);
        throw new Error(error);
    }

}


const downloadTripPlanner = async(destinationID, tripPlannerID, lang) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, tripPlannerID, lang}),
            ...POST_OPTS()
        }

        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/downloadTripPlanner`, options);

        
        const blob = await response.blob();
        let file = window.URL.createObjectURL(blob);
        window.open(file);
    } catch (error) {
        console.error(error);
        return null;
    }
}


export default {
    getTripPlanner,
    getTripPlannerList,
    createTripPlanner,
    updateTripPlanner,
    deleteTripPlanner,
    downloadTripPlanner
}