import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig



/* **************************************************************************************
 *                                  PARTNER
 ************************************************************************************** */

/*
    Return [partner] where destinationID 
*/
const getPartners = async (destinationID) => {

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPartners`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
    Return {partner} where partnerID and destinationID 
*/
const getPartner = async (destinationID, partnerID) => {

    if (!destinationID) throw new Error('destinationID is required required')
    if (!partnerID) throw new Error('partnerID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, partnerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPartner`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
*/
const createPartner = async (destinationID, payload) => {


    delete payload._id

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, payload }),
            ...POST_OPTS()
        }
        const partner = await fetch(`${API_URL}${SERVICE_ROUTE}/createPartner`, options);
        return await partner.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}

/*
*/
const updatePartner = async (destinationID, partnerID, payload) => {

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, partnerID, payload }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updatePartner`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}

/*
*/
const deletePartner = async (destinationID, partnerID) => {

    if (!partnerID) throw new Error('partnerID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, partnerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deletePartner`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}




export default {
 getPartner,
 getPartners,
 createPartner,
 updatePartner,
 deletePartner   
}