import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig

/* **************************************************************************************
 *                                 GIFTCARD
 ************************************************************************************** */


const getGiftCards = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getGiftCards`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const getGiftCard = async (destinationID, giftcardID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!giftcardID) throw new Error('giftcardID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, giftcardID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getGiftCard`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const createGiftCard = async (destinationID, payload) => {

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, payload }),
            ...POST_OPTS()
        }
        const pass = await fetch(`${API_URL}${SERVICE_ROUTE}/createGiftCard`, options);
        return await pass.json();
    } catch (error) {

        console.error(error);
        return error;
    }
}

/*
*/
const updateGiftCard = async (destinationID, giftcardID, payload) => {

    if (!destinationID) throw new Error('destinationID is required required')
    if (!giftcardID) throw new Error('giftcardID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, giftcardID, payload }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateGiftCard`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }

}

/*
*/
const deleteGiftCard = async (destinationID, giftcardID) => {

    if (!giftcardID) throw new Error('giftcardID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, giftcardID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deleteGiftCard`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }

}





export default {
    getGiftCards,
    getGiftCard,
    createGiftCard,
    updateGiftCard,
    deleteGiftCard
}