import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";



import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formPaper: {
        width: "100%",
        marginBottom: "15px",
        padding: theme.spacing(2)
    },
    container: {
        padding: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    adornment: {
        marginRight: 0
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    button: {
        margin: theme.spacing(1),
    },

}));



export default ({ dealList, lang, handleChange, map }) => {

    const classes = useStyles();

    return (
        <Accordion className={classes.formPaper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{`Map`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item xs={12}>
                    <GoogleMap lang={lang} dealList={dealList} map={map} handleChange={handleChange} />
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}






const MapBox = ({ google, lang, dealList, map, handleChange }) => {

    
    const onCenterChanged = (props, gmap) => {
        handleChange({...map, center: {
                lat: gmap.center.lat(),
                lng: gmap.center.lng()
            }
        })   
    }
    const onZoomChanged = (props, gmap) => {
        handleChange({...map, zoom: gmap.zoom})
    }

    var initialCenter = map? map.center : null;
    var initialZoom = map? map.zoom : 8;


    

    const Markers = dealList.map((deal, i) => {
        const { address } = deal
        const { long, lat } = address.geoLocation;
        const dealData = deal.dealData.find(data => data.lang === lang)
        if(!initialCenter && i == 0) {
            initialCenter = {};
            initialCenter.lat = long;
            initialCenter.lng = lat;
        }
        return <Marker title={dealData.name} name={dealData.name} position={{ lat: long, lng: lat }} />
    })


    if(!map && initialCenter) {
        handleChange({center: initialCenter, zoom: initialZoom})
    }


    console.log(initialCenter);
    console.log(initialZoom);



    return (

        <div className="map">
            <div className="mapBox" style={{
                position: "relative",
                width: "100%",
                height: "auto",
                minHeight: "450px"
            }}>

                {initialCenter? 
                    <Map google={google} zoom={initialZoom} initialCenter={initialCenter}
                    onZoomChanged={onZoomChanged}
                    onCenterChanged={onCenterChanged}
                >
                    {Markers}
                </Map>:

                    <div style={{
                        display: "flex",
                        fontSize: "25px",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "450px"
                    }}> Please add first deal in order to see map! </div>
                    
                 }
                
            </div>
        </div>
    );
}

const GoogleMap = GoogleApiWrapper({
    apiKey: ("AIzaSyBw8oSp5nAJC43HjK1GjFRR7hLhkGRezcQ")
})(MapBox)