import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig

/* **************************************************************************************
 *                                  DESTINATION
 ************************************************************************************** */

/*
    Return {destination} where destinationID 
*/
const getDestinations = async () => {
    try {
        const options = POST_OPTS();
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getDestinations`, options);
        const destinations = await response.json();

        return destinations;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/*
    Return {destination} where destinationID 
*/
const getDestination = async (destinationID) => {

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getDestination`, options);
        const destinations = await response.json();
        return destinations;
    } catch (error) {

        console.error(error);
        return null;
    }
}


const updateDestination = async(destinationID, payload) => {

    console.log("updateDestination")
    const data = new FormData();

    const {name, slug, webUrl, webData, coverImage, uploadCoverImage, videoURL} = payload

    let uploadImages = {}
    for (const webSection in webData) {
        uploadImages[webSection] = []
        for(let image in webData[webSection].uploadImgFiles) 
            uploadImages[webSection].push(webData[webSection].uploadImgFiles[image])
        delete webData[webSection].uploadImgFiles
     }
     console.log("IMAGES", uploadImages)

     let uploadVideos = {}
     for (const webSection in webData) {
        if(webData[webSection].uploadVideoFile)
            uploadVideos[webSection] = webData[webSection].uploadVideoFile.file
        delete webData[webSection].uploadVideoFile
      }
      console.log("VIDEOS", uploadVideos)

    
     data.append('payload', JSON.stringify({name, slug, webData, webUrl, videoURL, coverImage}))
     data.append('destinationID', destinationID)


     if(uploadCoverImage)
      data.append('cover-image', uploadCoverImage)
    
    for (const webSection in uploadImages) {
       for(let image in uploadImages[webSection])
            data.append(`${webSection}-images`, uploadImages[webSection][image])
    }

    for (const webSection in uploadVideos) {
        data.append(`${webSection}-videos`, uploadVideos[webSection])
    }


    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateDestination`, options);
        const destinations = await response.json();
        return destinations;
    } catch (error) {

        console.error(error);
        return null;
    }
}



const saveWebConfig = async (webConfig) => {
    if (!webConfig) throw new Error('webConfig is required required')
    try {
        const options = {
            body: JSON.stringify({ webConfig }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/saveWebConfig`, options);
        const webConfigResponse = await response.json();
        return webConfigResponse;
    } catch (error) {
        console.error(error);
        return null;
    }
}

const getWebConfig = async () => {

    try {
        const options = {
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getWebConfig`, options);
        const webConfig = await response.json();
        return webConfig;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export default {
    getDestinations,
    getDestination,
    updateDestination,

    saveWebConfig,
    getWebConfig
}