import React, { useState, useEffect, Fragment } from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom";

import Loading from '../../components/Loading'
import API from '../../network'
import toSlug from '../../helpers/toSlug'

export default (props) => {
    const { match, history, user } = props;
    const { destinationID } = match.params;

    const [widgets, setWidgets] = useState([])
    const [partners, setPartners] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setFetching(true)
        API.widget.getWidgets(destinationID).then((widgets) => {
            setWidgets(widgets)
            API.partner.getPartners(destinationID).then(partners => {
                setPartners(partners)
                setFetching(false)
            }).catch(e => console.log(e))
        }).catch(error => console.log(error))
    }, [])


    const WidgetItems = widgets.map(widget => {

        const getPartnerName = () => {
            const partner = partners.find(partner => partner._id === widget.partnerID)
            return partner ? partner.slug : ''
        }
        return (
            <tr className="widget-link" onClick={() => history.push(`${match.url}/${widget._id}`)}>
                <td>{widget.slug}</td>
                <td>{widget.name}</td>
                <td>{getPartnerName()}</td>
                <td>{widget.deals.length}</td>
                <td>{widget.url}</td>
            </tr>
        )
    })

    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="widgets">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        {/* <Search /> */}
                        <div className="dashboard__btn">
                        <Link to={`${match.url}/newWidget`}>
                            <div className="btn btn--fill btn--green" id="widgetNew">Add New IQM Widget</div>
                        </Link>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                                <tr className="widget-link" data-href="url:/">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Partner Name</th>
                                    <th>Deals included</th>
                                    <th>URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {WidgetItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}