import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Loading from '../../components/Loading'
import API from '../../network'


export default (props) => {

  const { match, history, user } = props;
  const { destinationID } = match.params;

  const [giftcards, setGiftcards] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    setFetching(true)
    API.giftcard.getGiftCards(destinationID).then((giftcards) => {
      setGiftcards(giftcards)
      setFetching(false)
    }).catch(error => console.log(error))
  }, [])


  const GiftCardItems = giftcards.map((giftcard) => {

    return (

      <tr className="giftcard-link" onClick={() => history.push(`${match.url}/${giftcard._id}`)}>
        <td>{giftcard.name}</td>
        <td>{giftcard.giftType}</td>
        <td>{giftcard.code}</td>
        <td>{`${giftcard.discount}%`}</td>
        <td>{`${giftcard.used} / ${giftcard.quantity}`}</td>
        <td>{giftcard.status}</td>
      </tr>
    )

  })

  if (fetching) return <Loading />
  return (
    <div className="dashboard section">
      <div className="main show" id="giftcard">
        <div className="dashboard__main">
          <div className="dashboard__row">
            <div className="dashboard__btn">
              <div className="btn btn--fill btn--green" id="giftcardNew"><Link to={`${match.url}/newGiftCard`}>Add New IQM Pass</Link></div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <thead>
                <tr className="pass-link" data-href="url:/">
                  <th>Name</th>
                  <th>Type</th>
                  <th>Code</th>
                  <th>Discount</th>
                  <th>Quantity</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {GiftCardItems}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}