import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig

/* **************************************************************************************
 *                                 NOTIFICATION
 ************************************************************************************** */
/*
    Return [notification] where destinationID 
*/
const getNotifications = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getNotifications`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
    Return {notification} where notificationID and destinationID 
*/
const getNotification = async (destinationID, notificationID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!notificationID) throw new Error('notificationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, notificationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getNotification`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}







export default {
    getNotification,
    getNotifications
}