import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


import API from '../../network'
import Loading from '../../components/Loading'


export default (props) => {
    const {match, user} = props;
    const [destinations, setDestinations] = useState([])
    const [fetching, setFetching] = useState(false)
    
    useEffect(()=>{
        setFetching(true)
        API.destination.getDestinations().then((destinations)=>{
            console.log(destinations)
            setDestinations(destinations)
            setFetching(false)
        }).catch(error=>console.log(error))
    },[])


  
    const DestinationItems = destinations.map((destination)=>{
      return (<Link key ={destination._id} to={`${match.url}/${destination._id}`} >
        <div className="destinationItem">
        <div className="destination__name">{destination.name}</div>
        </div>
      </Link>)
      })

    if(fetching) return <Loading fullScreen={true}/>

    
    return (
      <div className="dashboard section">
          <div className="main show" id="destinations">
            <div className="dashboard__main destination">
              <div className="dashboard__row">
                <div className="container">
                <div className="dashboard__btn">
                <Link className="btn btn--fill btn--green" to={`${match.url}/config`}> Web Config </Link>
                </div>
                  {/* <Search /> */}
                  {/* <div className="dashboard__btn">
                    <div className="btn btn--fill btn--green" id="destinationNew">Add new destination</div>
                  </div> */}
                </div>
              </div>
              <div className="destination-list">
                <div className="container">
                  {DestinationItems}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      );
}


