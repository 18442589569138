import React, { useState, useEffect } from 'react';


import Loading from '../../components/Loading'
import API from '../../network'

import TeaserItem from './TeaserItem'
import toSlug from '../../helpers/toSlug'


export default (props) => {

  const { match, history } = props;
  const { destinationID, passID } = match.params;

  const [slug, setSlug] = useState('')
  const [name, setName] = useState('')
  const [passType, setPassType] = useState('SINGLE')
  const [duration, setDuration] = useState('24')

  let price = {currency:'', value: null}
  const [priceEUR, setPriceEUR] = useState({...price, currency:'EUR'})
  const [priceHRK, setPriceHRK] = useState({...price, currency:'HRK'})
  const [teasers, setTeasers] = useState([])



  const [fetching, setFetching] = useState(false)

  const [activeTeaser, setActiveTeaser] = useState({
    view: false,
    teaser: null
  }) 

  const handleTeasersChange = (teaserResponse)=>{
      
      setActiveTeaser({view:false, teaser: null})
      if(!teaserResponse.teaser) return
        
      
      const index = teasers.findIndex((teaser)=> teaser.slug === teaserResponse.teaser.slug)
      if(index > -1){
        teasers[index] = teaserResponse.teaser
      }
      else{
        teasers.push(teaserResponse.teaser)
      }
    
      setTeasers([...teasers])

        

  }
  
  const handleDeleteTeaser = (teaser) => {
    setActiveTeaser({view:false, teaser: null})
    
    const index = teasers.indexOf(teaser)
    if (index > -1) teasers.splice(index, 1)
    setTeasers([...teasers])
  }

  useEffect(() => {
    if(passID) {
      setFetching(true)
      API.pass.getPass(destinationID, passID).then(pass => {

        setSlug(pass.slug)
        setName(pass.name)
        setPassType(pass.passType)
        setDuration(pass.duration)

        if (pass.price) {
          pass.price.forEach(data => {
              if (data.currency === 'HRK' ) setPriceHRK(data)
              if (data.currency === 'EUR') setPriceEUR(data)
          })
        }
        if(pass.teasers) setTeasers(pass.teasers)
        setFetching(false)
      }).catch(error => console.log(error))
    }
  }, [])


  const handleSubmit = async () => {
    setFetching(true)
    const { destinationID, passID } = match.params

    const pass = {
        slug: toSlug(name),
        name,
        duration,
        passType,
        price: [priceHRK, priceEUR],
        teasers,
        destinationID
    }
    try {
      if (passID) {
        await API.pass.updatePass(destinationID, passID,pass)
          // TODO: FLASH MESSAGE
          alert("Save success")
     }
     else {
        const passRes = await API.pass.createPass(destinationID, pass) 
        if(passRes._id)
          history.push(match.url.replace("/newPass", ""))
     }
     
    } catch (error) {
      console.log(error)
    }
    setFetching(false)
  }


  const handleDelete = async () => {
    setFetching(true)
    const {destinationID, passID} = match.params
    // Todo: Handle with dialog
    const res = await API.pass.deletePass(destinationID, passID)
    if(res.ok === 1 ) {
      const {url} = match
      history.push(url.replace(`/${passID}`, ""))

  } else {
      
  }

    setFetching(false)
  }

  if(activeTeaser.view) return <TeaserItem activeTeaser = {activeTeaser} onTeaserChange= {handleTeasersChange} deleteTeaser= {handleDeleteTeaser}  destinationID = {destinationID} />
  if(fetching) return <Loading />
  return (
    <div className="dashboard section">
    <div className="main show" id="passItem">
      <div className="dashboard__main">
        <div className="dashboard__row">
          <div className="dashboard__btn">
            <div className="btn btn--fill btn--yellow" id="passBack" onClick={() => history.goBack()} >Back</div>
          </div>
          <div className="dashboard__title">{passID ? "Pass Edit" : "Pass Create"}</div>
        </div>

        <div className="form-wrap">
          <form className="form">
            <div className="form__item">
              <label htmlFor="name">Pass Name*</label>
              <input className="name" type="text" name="name" placeholder="Enter pass name" required="required" value={name} onChange={e=> setName(e.target.value)}/>
            </div>
            
            <TypeForm passType={passType} handleChange={setPassType}/>
            <DurationForm duration={duration} handleChange={setDuration}/>

            <PriceForm priceEUR={priceEUR} priceHRK={priceHRK} handleChangeHRK={setPriceHRK} handleChangeEUR={setPriceEUR}/>
            <div className="form__item">
              <div className="button-wrap">
                <div className="btn btn--red btn--fill" id="passDelete" onClick={handleDelete}>Delete Pass</div>
                <input className="btn btn--green btn--fill" value="Save"  onClick={handleSubmit}/>
              </div>
            </div>
          </form>
        </div>

        <TeaserList teasers={teasers} setActiveTeaser={setActiveTeaser}/>

      </div>
    </div>
    </div>
  );
}


const TypeForm = ({handleChange, passType}) => {
  console.log(passType)
  const optionChanged = e => {
    handleChange(e.target.value.toUpperCase())
}
  return(<div className="form__item">
  <label htmlFor="type">Pass Type*</label>
  <select name="type" onChange={optionChanged} value={passType.toLowerCase()}>
    <option value="single">Single</option>
    <option value="couple">Couple</option>
    <option value="family">Family</option>
    <option value="team">Team</option>
    <option value="children">Children</option>
  </select>
</div>)
}

const DurationForm = ({handleChange, duration}) => {
  const optionChanged = e => {
    handleChange(e.target.value.toUpperCase())
}
  return(<div className="form__item">
  <label htmlFor="duration">Pass Duration*</label>
  <select name="duration" onChange={optionChanged} value={duration}>
    <option value="24">24h (1d)</option>
    <option value="72">72h (3d)</option>
    <option value="168">168h (7d)</option>
    <option value="336">336h (14d)</option>
  </select>
</div>)
}


const PriceForm = ({priceEUR, priceHRK, handleChangeEUR, handleChangeHRK}) =>{

  const inputChange = (event) => {
    const { name, value } = event.target

    if (name === 'priceHRK')
        // handleChangeHRK({currency:'HRK',value:parseFloat(value) })
        handleChangeHRK({currency:'HRK',value })
    else if (name === 'priceEUR')
        // handleChangeEUR({currency:'EUR', value:parseFloat(value) })
        handleChangeEUR({currency:'EUR', value })
}

  return(<div className="form__item">
  <div className="form__field-title">Pass Price</div>
  <div className="form__field">
    <div className="form__item">
      <div className="formWrap">
        <div className="form__item">
          <label htmlFor="price">Pass Price (HRK)*</label>
          <input className="price" type="text" name="priceHRK" placeholder="Enter pass price" value={priceHRK.value} required="required" onChange={inputChange}/>
        </div>
        <div className="form__item">
          <label htmlFor="price">Pass Price (EUR)*</label>
          <input className="price" type="text" name="priceEUR" placeholder="Enter pass price" value={priceEUR.value} required="required" onChange={inputChange}/>
        </div>
      </div>
    </div>
  </div>
</div>
)
}

const TeaserList = ({teasers, setActiveTeaser}) => {
  
  const TeaserItems = teasers.map((teaser)=> {
    return(  
      <tr className="deal-link" onClick = {()=> setActiveTeaser({view: true, teaser})}>
        <td>{teaser.slug}</td>
        <td>{teaser.name}</td>
        <td>{teaser.deals.length}</td>
      </tr>
    )
  })

  return(  <div className="form-wrap">
  <form className="form">
    <div className="form__field-title">Teaser List</div>
    
    <div className="form__item">
      <div className="button-wrap">
        <div className="btn btn--green btn--fill" id="passDelete" onClick={e=> setActiveTeaser({view: true, teaser: null})}>Create new Teaser</div>
      </div>
    </div>

    <div className="form__item">
      <div className="table-wrap table-wrap--nopadding">
        <table className="table">
            <thead>
              <tr className="teaser-link" data-href="url:/">
                <th>ID</th>
                <th>Name</th>
                <th>Deals included</th>
              </tr>
            </thead>
          {TeaserItems}
        </table>
      </div>
    </div>
  </form>
</div>
)
}