import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


export default  (props) => {
    // return(<div className="navBar">
    //     <div onClick={() => {
    //         Auth.logoutAdmin();
    //         props.handleLogout(null)}}>Logout</div>
    //     </div>)


	
	const {handleLogout} = props
return (
    <div className="navBar">
		<div className="container">
			<div className="navBar__logo" >
				<img src="/img/logos/iqm-logo.png" alt="IQM Destination" />
			</div>
			<div className="navBar__btn"></div>
			<div className="navBar__mask"></div>
			<div className="navBar__nav">
				<div className="navBar__close"></div>
				<ul>
					<li>
						<div className="user" onClick={handleLogout}>
							<div className="user-img">
								<img src="/img/icons/user.svg" alt="User icon" />
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
  );
}