import React, { useState, useEffect, useMemo, Fragment } from 'react';


/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";



import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



import { Container as DNDContainer, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from 'array-move';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formPaper: {
        width: "100%",
        marginBottom: "15px",
        padding: theme.spacing(2)
    },
    container: {
        padding: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    adornment: {
        marginRight: 0
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    button: {
        margin: theme.spacing(1),
    },

}));

export default ({dealList, removeDeal, arangeDeals, title}) => {
    const classes = useStyles();
    

    const onDrop = ({ removedIndex, addedIndex }) => {
        var dealListID = arrayMoveImmutable(dealList, removedIndex, addedIndex)
        arangeDeals(dealListID.map(d => d._id));
    };

    // useEffect(()=>{

    // }, [dealList])
    return (
        <List>
            <DNDContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {dealList.map(({ _id, name }) => (
                    <Draggable key={_id}>
                        <ListItem className="drag-handle">

                            <ListItemIcon >
                                <DragIndicatorIcon />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                            <ListItemSecondaryAction onClick={()=>{removeDeal(_id)}}>
                                <ListItemIcon >
                                    <DeleteIcon />
                                </ListItemIcon>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Draggable>
                ))}
            </DNDContainer>
        </List>
    );
};