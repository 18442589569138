import React, { useState, useEffect, Fragment } from 'react';


import Loading from '../../components/Loading'
import API from '../../network'

import toSlug from '../../helpers/toSlug'

export default (props) => {

    const { match, history } = props;
    const { destinationID, widgetID } = match.params;

    const [widgetName, setWidgetName] = useState('')
    const [widgetPartner, setWidgetPartner] = useState({ slug: '', _id: '' })
    const [widgetUrl, setWidgetUrl] = useState('')
    const [widgetDeals, setWidgetDeals] = useState([])


    const [deals, setDeals] = useState([])
    const [partners, setPartners] = useState([])

    const [destination, setDestination] = useState({})

    const [fetching, setFetching] = useState(false)

    const addToWidget = (deal) => {
        setWidgetDeals([deal, ...widgetDeals])
    }
    const removeFromWidget = (deal) => {
        const index = widgetDeals.indexOf(deal)
        if (index > -1) widgetDeals.splice(index, 1)
        setWidgetDeals([...widgetDeals])
    }



    const handleSubmit = async () => {
        setFetching(true)

        const widget = {
            name: widgetName,
            slug: toSlug(widgetName),
            deals: widgetDeals.map(deal => deal._id),
            partnerID: widgetPartner._id,
            url: widgetUrl,
            destinationID
        }

        if (widgetID) {
            const widgetRes = await API.widget.updateWidget(destinationID, widgetID, widget)
            alert("Save success")
        }
        else {
            const widgetRes = await API.widget.createWidget(destinationID, widget)
            if (widgetRes._id) {
                const { url } = match
                history.push(url.replace("/newWidget", ""))
            }
        }

        setFetching(false)
    }


    const handleDelete = async () => {
        setFetching(true)
        const res = await API.widget.deleteWidget(destinationID, widgetID)

        if (res.ok === 1) {
            const { url } = match
            history.push(url.replace(`/${widgetID}`, ""))
        } else {

        }
        setFetching(false)
    }


    const loadData = async (destinationID, widgetID) => {
        try {
            const deals = await API.deal.getActiveDeals(destinationID);
            const partners = await API.partner.getPartners(destinationID)

            setPartners(partners)
            setDeals(deals)

            if (widgetID) {
                const widget = await API.widget.getWidget(destinationID, widgetID)
                const partner = await API.partner.getPartner(destinationID, widget.partnerID)
                setWidgetName(widget.name)
                setWidgetUrl(widget.url)
                setWidgetDeals(deals.filter((deal) => {
                    if (widget.deals.find((id) => id === deal._id))
                        return deal
                }))
                const { _id, slug } = partner
                setWidgetPartner({ _id, slug })

                const destination = await API.destination.getDestination(destinationID)
                setDestination(destination)
            }
            return { success: true }
        } catch (error) {
            console.log(error)
            return { success: false, error }
        }
    }


    useEffect(() => {
        setFetching(true)
        loadData(destinationID, widgetID).then(res => {
            if (res.success)
                setFetching(false)

        }).catch(e => console.log(e))
    }, [])




    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="widgetsItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="widgetBack" onClick={() => props.history.goBack()}>Back</div>
                        </div>
                        <div className="dashboard__title">Widget Edit</div>
                    </div>
                    <div className="table-wrap">
                        <div className="table__title">Deals Included in Widget</div>
                        <WidgetDealsTable widgetDeals={widgetDeals} partners={partners} removeFromWidget={removeFromWidget} />

                        <div className="form">
                            <div className="form__field">

                                <div className="form__item">
                                    <label htmlFor="widget_name">Widget Name</label>
                                    <input className="widget_name" type="text" name="widget_name" placeholder="Enter Widget name" value={widgetName} onChange={e => setWidgetName(e.target.value)} />
                                </div>

                                <WidgetPartner widgetID={widgetID} widgetPartner={widgetPartner} partners={partners} handleChange={setWidgetPartner} />
                                <div className="form__item">
                                    <label htmlFor="widget_url">Widget URL</label>
                                    {
                                        widgetID ?
                                            <input className="widget_url" type="text" name="widget_url" value={widgetUrl} readOnly disabled={true} /> :
                                            <input className="widget_url" type="text" name="widget_url" placeholder="Enter Widget Url" value={widgetUrl} onChange={e => setWidgetUrl(e.target.value)} />
                                    }

                                </div>

                                <div className="form__item">
                                    <div className="button-wrap">
                                        {widgetDeals.length ? <div className="btn btn--green btn--fill" onClick={handleSubmit}>{widgetID ? 'Save Widget' : 'Create Widget'}</div> : null}
                                        {widgetDeals.length && widgetID ? <div className="btn btn--red btn--fill" onClick={handleDelete}> Delete Widget</div> : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {widgetID ? <WidgetCode destination={destination} widgetName={widgetName} widgetUrl={widgetUrl} /> : null}


                    <div className="table-wrap">
                        <div className="table__title">Deals List</div>
                        <DealsTable deals={deals} partners={partners} widgetDeals={widgetDeals} addToWidget={addToWidget} />
                    </div>
                </div>
            </div>
        </div>
    );
}



const WidgetDealsTable = ({ widgetDeals, partners, removeFromWidget }) => {

    const WidgetItems = widgetDeals.map(deal => {

        const getPartnerName = () => {
            const partner = partners.find(partner => partner._id === deal.partnerID)
            return partner ? partner.slug : ''
        }
        const categoriesList = (categories) => {
            let categoryString = ''
            for (let i = 0; i < categories.length; i++)
                categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
            return categoryString
        }

        const priceHRK = deal.price.find(p => p.currency === 'HRK')
        const priceEUR = deal.price.find(p => p.currency === 'EUR')

        return (<tr className="deal-link" data-href="url:/">
            <td>{deal.slug}</td>
            <td>{deal.name}</td>
            <td>{getPartnerName(partners)}</td>
            <td>{categoriesList(deal.categories)}</td>
            <td>{`${deal.discount[0].value}%`}</td>
            <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
            <td>
                <div className="btn btn--red btn--fill" onClick={() => { removeFromWidget(deal) }}>Remove</div>
            </td>
        </tr>)

    })

    return (
        <table className="table">
            <thead>
                <tr className="deal-link" data-href="url:/">
                    <th>ID</th>
                    <th>Name</th>
                    <th>Partner Name</th>
                    <th>Type</th>
                    <th>Discount</th>
                    <th>Price (Eur/Hrk)</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>{WidgetItems}</tbody>
        </table>)
}


const DealsTable = ({ deals, partners, widgetDeals, addToWidget }) => {

    const DealItems = deals.map(deal => {

        const getPartnerName = () => {
            const partner = partners.find(partner => partner._id === deal.partnerID)
            return partner ? partner.slug : ''
        }

        const categoriesList = (categories) => {
            let categoryString = ''
            for (let i = 0; i < categories.length; i++)
                categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
            return categoryString
        }

        const priceHRK = deal.price.find(p => p.currency === 'HRK')
        const priceEUR = deal.price.find(p => p.currency === 'EUR')

        const includes = widgetDeals.includes(deal)
        const rowClass = 'deal-link' + (includes ? ' table--paused' : '')

        return (<tr className={rowClass} data-href="url:/">
            <td>{deal.slug}</td>
            <td>{deal.name}</td>
            <td>{getPartnerName(partners)}</td>
            <td>{categoriesList(deal.categories)}</td>
            <td>{`${deal.discount[0].value}%`}</td>
            <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
            <td>
                {(!includes) ? <div className="btn btn--green btn--fill" onClick={() => { addToWidget(deal) }}>Add to list</div> : null}
            </td>
        </tr>)

    })

    return (<table className="table">
        <thead>
            <tr className="deal-link" data-href="url:/">
                <th>ID</th>
                <th>Name</th>
                <th>Partner Name</th>
                <th>Type</th>
                <th>Discount</th>
                <th>Price (Eur/Hrk)</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {DealItems}
        </tbody>
    </table>)
}


// Done !
const WidgetPartner = ({ widgetID, widgetPartner, partners, handleChange }) => {


    const Options = partners.map(partner => <option key={partner._id} value={partner._id} >{partner.slug}</option>)
    const optionChanged = e => {
        const partner = partners.find(partner => partner._id === e.target.value)
        handleChange(partner)
    }


    if (widgetID) {
        return (<div className="form__item">
            <label htmlFor="partner">Deal Partner</label>
            <input className="partner" type="text" name="partner" disabled={true} value={widgetPartner.slug} readonly />
        </div>)
    }
    else {
        return (<div className="form__item">
            <label htmlFor="partner-list">Deal Partner</label>
            <select name="partner-list" required="required" onChange={optionChanged} >
                <option value="null" >Select Partner</option>
                {Options}
            </select>
        </div>)
    }

}


const WidgetCode = ({ destination, widgetName, widgetUrl }) => {

    console.log(destination)
    const [widgetPosition, setWidgetPosition] = useState('br')
    const [widgetSize, setWidgetSize] = useState('default')

    const optionChanged = e => {
        setWidgetPosition(e.target.value)
    }

    const optionChangedSize = e => {
        setWidgetSize(e.target.value)
    }




    const url = `https://iqmpass.com/en/${destination.webUrl}/widget/${widgetUrl}`

    let widgetCardSrc = ""
    switch (destination.slug) {
        case 'visit-golden-slavonia':
            widgetCardSrc = "https://assets.iqmpass.com/pdf/widget/card-slavonija.png"
            break;
        case 'visit-losinj':
            widgetCardSrc = "https://assets.iqmpass.com/pdf/widget/card-losinj.png"
            break;
        case 'visit-karlovac':
            widgetCardSrc = "https://assets.iqmpass.com/pdf/widget/card-karlovac.png"
            break;
        case 'visit-lika-destination':
            widgetCardSrc = "https://assets.iqmpass.com/pdf/widget/card-lika.png"
            break;
        case 'visit-vodice':
            widgetCardSrc = "https://assets.iqmpass.com/pdf/widget/card-vodice.png"
            break;
        default:
            break;
    }

    let widgetClassName = ""
    switch (widgetPosition) {
        case 'tl':
            widgetClassName = "widget widget--float widget--bottom-left"
            break;
        case 'tr':
            widgetClassName = "widget widget--float widget--top-right"
            break;
        case 'bl':
            widgetClassName = "widget widget--float widget--bottom-left"
            break;
        case 'br':
            widgetClassName = "widget widget--float widget--bottom-right"
            break
        default:
            break;
    }

    widgetClassName = widgetSize === "small" ? `${widgetClassName} widget--small-180`: widgetClassName



    const code = ` 
    <div class="${widgetClassName}" id="widget">
          <div class="widget__btn" onclick="document.getElementById('widget').className = 'hide' ">
            <svg class="feather feather-x-circle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M15 9l-6 6M9 9l6 6"></path>
            </svg>
          </div>
          <a class="widget__item" href="${url}" name="IQM Pass Widget" title="IQM Pass Widget">
            <div class="widgetCard">
              <div class="widget__name">
                ${widgetName}
              </div>
              <div class="widgetCard__img">
                <img src="${widgetCardSrc}" alt="" />
              </div>
              <div class="widgetCard__row widgetCard__row--green">
                <h2 class="widgetCard__h1">Get your pass now!</h2>
              </div>
            </div>
            <div class="widget__mask"></div>
          </a>
        </div>
        `

    return (<div className="dashboard__row">

        <div className="form">
            <div className="form__item">
                <label htmlFor="code">Paste IQM Pass styles to <code>&lt;head&gt;</code> tag</label>

                <code> {`<link rel="stylesheet" href="https://assets.iqmpass.com/widget.css" />`}</code>
            </div>

            <div className="form__field">
                <div className="formWrap">
                    <div className="form__item">
                        <label htmlFor="widget-position">Choose widget floating position</label>
                        <select name="widget-position" onChange={optionChanged} value={widgetPosition} >
                            <option value="tl"> Top-Left</option>
                            <option value="tr">Top-Right</option>
                            <option value="bl">Bottom-Left</option>
                            <option value="br">Bottom-Right</option>
                        </select>
                    </div>
                    <div className="form__item">
                        <label htmlFor="widget-position">Choose widget size</label>
                        <select name="widget-position" onChange={optionChangedSize} value={widgetSize} >
                            <option value="default"> Default</option>
                            <option value="small">Small</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="form__item">
                <label htmlFor="">Paste IQM Pass widget <code>html</code> anywhere in  <code>&lt;body&gt;</code> tag </label>
                <code>
                    {code}
                </code>
            </div>
        </div>

    </div>)
}