import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import FuzzySearch from 'fuzzy-search';

import API from '../../network'
import Loading from '../../components/Loading'



export default (props) => {

    const { match, user, history } = props;

    const { destinationID } = match.params;

    const [orders, setOrders] = useState([])
    const [fetching, setFetching] = useState(false)
    const [pageIndex, setPageIndex] = useState(1)

    const [filters, setFilters] = useState({ search: "", showAll: false })

    const displaySize = 10;

    useEffect(() => {
        setFetching(true)
        API.order.getOrders(destinationID).then((orders) => {
            setOrders(orders)
            setFetching(false)
        }).catch(e => console.log(e))
    }, [])


    // First applay all filters 
    let orders_f = orders.filter(order => {
        if(filters.showAll)
            return true
        else
            return order.status === 'ACCEPTED'
    });

    const searcher = new FuzzySearch(orders_f, ['customer.CustomerEmail', '_id'], {
        sort: true,
    });
    if(filters.search)
        orders_f = searcher.search(filters.search);

    // Second applay pagination
    const orders_p = orders_f.slice((pageIndex - 1) * displaySize, pageIndex * displaySize)
    console.log("Pagination index", (pageIndex - 1) * displaySize, pageIndex * displaySize)


    const OrderItems = orders_p.map((order, index) => {
        if(order.status === "PENDING")
            return (
                <tr className="order-link" >
                    <td>{order._id}</td>
                    <td style={{color: "blue"}}>{order.status}</td>
                    <td>{order.pass.name}</td>
                    <td></td>
                </tr>
            )

        else if(order.status === "ACCEPTED")    
            return (
                <tr className="order-link" onClick={() => history.push(`${match.url}/${order._id}`)}>
                    <td>{order._id}</td>
                    <td style={{color: "green"}}>{order.status}</td>
                    <td>{order.pass.name}</td>
                    <td>{order.customer ? order.customer.CustomerEmail : ""}</td>
                </tr>
            )
        else
        return (
            <tr className="order-link" onClick={() => history.push(`${match.url}/${order._id}`)}>
                <td>{order._id}</td>
                <td style={{color: "red"}}>{order.status}</td>
                <td>{order.pass.name}</td>
                <td>{order.customer ? order.customer.CustomerEmail : ""}</td>
            </tr>
        )    
    })


    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="orders">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <Filters filters={filters} setFilters={setFilters} setPageIndex={setPageIndex}/>

                        {/* <div className="dashboard__btn">
                            <div className="btn btn--fill btn--green" id="orderNew">Add New IQM Order</div>
                        </div> */}
                    </div>
                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                                <tr className="deal-link" data-href="url:/">
                                    <th>ID</th>
                                    <th>STATUS</th>
                                    <th>PASS</th>
                                    <th>CUSTOMER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {OrderItems}
                            </tbody>
                        </table>
                        <Pagination pageIndex={pageIndex} setPageIndex={setPageIndex} listSize={orders_f.length} displaySize={displaySize} />
                    </div>
            
                </div>
            </div>
        </div>
    );
}





const Pagination = ({ pageIndex, setPageIndex, listSize, displaySize }) => {

    const PaginationItems = []
    // const paginationSize = listSize / displaySize + (listSize % displaySize === 0 ? 0 : 1);
    const paginationSize = listSize / displaySize;
    console.log(paginationSize)
    for (let i = 0; i < paginationSize; i++)
        PaginationItems.push(<div className="pagination__item" onClick={() => setPageIndex(i + 1)}>{i + 1}</div>)

    return (
        <div className="pagination">
            {PaginationItems}
        </div>
    )
}

const Filters = ({filters, setFilters, setPageIndex}) => {
    const onInputChange = (e) => {
        const { name, value } = e.target
        setFilters({ ...filters, search: value })
        setPageIndex(1)
    }
    const onCheckboxChange = (e) => {
        const { name, checked } = e.target
        setFilters({ ...filters, showAll: checked })
        setPageIndex(1)
    }

    return (
        <div className="search">
            <form className="form form--signin">
                <div className="form__item">
                    {/* <div className="form__icon form__icon--search"></div> */}
                    <input className="search" type="text" name="search" placeholder="Search" required="required" value={filters.search} onChange={onInputChange} />
                </div>
                <div className="form__item">
                    <label className="checkbox-label">
                        <input type="checkbox" value="All" name="all" checked={filters.showAll} onChange={onCheckboxChange} />
                        <span className="checkbox-custom rectangular"></span>
                        <span className="checkbox-span">Show All</span>
                    </label>
                </div>
            </form>
        </div>
    );
}


