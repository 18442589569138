import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";


export default ({ match, location, history, destination}) => {

    const {url} = match


    const disabledStyle = {
        "opacity": "0.65",
        "cursor": "not-allowed"
    }

    return (<div className="sidenav">
        <div className="sidenav__list">

            <Link className="sidenav__item " to={`/admin`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Destinations </div>
            </Link>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item " to={`${url}`} exact>
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> {`${destination.slug}`}</div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item " to={`${url}/partners`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Partners </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/deals`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Deals </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/trip-planner`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Trip Planner </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/passes`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Passes </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/itinerary`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Itinerary </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/orders`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Orders </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/widgets`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Widgets </div>
            </NavLink>
            <NavLink activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/giftcards`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Gift Cards </div>
            </NavLink>
            <NavLink style={disabledStyle} activeClassName = "sidenav__item--active" className="sidenav__item" to={`${url}/notifications`} >
                <div className="sidenav__icon"></div>
                <div className="sidenav__text"> Notifications </div>
            </NavLink>
        </div>
    </div>)

}


