import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


import PartnersList from './PartnersList'
import PartnerItem from './PartnerItem'


export default (props) => {
    const {match, user} = props;


    return(<Switch>
        {/* path: /admins/:destinationID/partners */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <PartnersList user={user} {...props }/> }></Route> 
        
        {/* path: /admins/:destinationID/partners/newPartner */ }
        <Route exact path={`${match.path}/newPartner`} render = {(props)=> <PartnerItem  user={user} {...props}/> }></Route>

        {/* path: /admins/:destinationID/partners/:partnerID */ }
        <Route path={`${match.path}/:partnerID`} render = {(props)=> <PartnerItem  user={user} {...props}/> }></Route>
    </Switch>)
}





