import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Loading from '../../components/Loading'
import API from '../../network'


export default ({match, history}) => {
  const { destinationID } = match.params;
  const [tripPlannerList, setTripPlannerList] = useState([])
  const [destination, setDestination] = useState(null)
  
  const [fetching, setFetching] = useState(true)


  const LoadData = async()=>{
      try {
        setFetching(true)
        const tripPlannerList = await API.tripPlanner.getTripPlannerList(destinationID);
        const destination = await API.destination.getDestination(destinationID)
        setTripPlannerList(tripPlannerList)
        setDestination(destination)
      } catch (error) {
          console.log(error)
      } finally {
        setFetching(false)
      }
  }

  useEffect(() => {
      try {
        LoadData()
      } catch (error) {
        console.log(error)
      }
  }, [])

  if (fetching) return <Loading />


  const TripPlannerItems = tripPlannerList.map((tripPlanner) => {

    return <TripPlanerItem tripPlanner={tripPlanner} destination={destination} match={match} history={history} />

  })

  return (
    <div className="dashboard section">
      <div className="main show" id="pass">
        <div className="dashboard__main">
          <div className="dashboard__row">
            {/* <Search /> */}

            <div className="dashboard__btn">
              <div className="btn btn--fill btn--green" id="trip-planner"><Link to={`${match.url}/new`}>New Trip Planner </Link></div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <thead>
                <tr className="pass-link" data-href="url:/">
                  <th>Name</th>
                  <th>Deals Count</th>
                  <th>Preview</th>
                  <th colSpan="2">PDF</th>
                </tr>
              </thead>
              <tbody>
                {TripPlannerItems}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}



const TripPlanerItem = ({tripPlanner, destination, match, history})=> {

  const { destinationID } = match.params;

  const [generatingPDF, setGeneratingPDF] = useState(false)

  const disabledStyle = {
    pointerEvents: "none",
    background: "rgba(128, 128, 128, 0.35)",
    border: "none"
  }

  const previewUrl = `https://iqmpass.com/hr/${destination.webUrl}/trip-planner/${tripPlanner.slug}`;
    const downloadTripPlanner = async (tripPlannerID, lang)=> {
      setGeneratingPDF(true);
      await API.tripPlanner.downloadTripPlanner(destinationID, tripPlannerID, lang);
      setGeneratingPDF(false);
    }

    return (
      <tr className="trip-planner-link">
        <td onClick={() => history.push(`${match.url}/${tripPlanner._id}`)}>{tripPlanner.name}</td>
        <td onClick={() => history.push(`${match.url}/${tripPlanner._id}`)}>{tripPlanner.deals.length}</td>
        <td><a className="btn btn--green btn--fill" href={previewUrl} target="blank">Preview</a></td>
        <td><a className="btn btn--blue btn--fill" target="blank" onClick={()=>{downloadTripPlanner(tripPlanner._id, "hr")}}
        style={generatingPDF?disabledStyle:{}}>{generatingPDF ? `Generating PDF`:`Download PDF HR`}</a></td>
        <td><a className="btn btn--blue btn--fill" target="blank" onClick={()=>{downloadTripPlanner(tripPlanner._id, "en")}}
        style={generatingPDF?disabledStyle:{}}>{generatingPDF ? `Generating PDF`:`Download PDF EN`}</a></td>
      </tr>
    )

}