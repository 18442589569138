
import React from "react";


export default (props) => {

        const fixStyles = {
                position: "relative",
                left: 0,
                top: 0,
                width: "100%",
                transform: "none"
        }
        const { message, success } = props
        const alertClassName = `alert ${success ? "alert--green" : "alert--red"} `
        
        return (<div style={fixStyles} className={alertClassName}>
                <div className="alert__text">{message}</div>
        </div>)
}
