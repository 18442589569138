
import React, { Fragment, useState, useEffect, Component } from "react";


import Loading from '../../components/Loading'
import FlashMessage from '../../components/FlashMessage'

import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import RichTextEditor from 'react-rte';




import API from '../../network'
import toSlug from '../../helpers/toSlug'
import deal from "../../network/deal";

console.log(RichTextEditor)
export default (props) => {


    const { match, history } = props;
    const { destinationID, dealID } = match.params;


    const [slug, setSlug] = useState('')
    const [name, setName] = useState('')
    const [status, setStatus] = useState('INACTIVE')
    const [categories, setCategories] = useState([])
    const [workingHours, setWorkingHours] = useState({ M: '', T: '', W: '', R: '', F: '', S: '', U: '' })
    const [gallery, setGallery] = useState([])
    const [video, setVideo] = useState('')

    const [coverImage, setCoverImage] = useState(null)
    const [uploadCoverImage, setUploadCoverImage] = useState(null)

    const [externalLink, setExternalLink] = useState({
        'booknowHR': {
            'name': 'booknow',
            'url': ''
        },
        'booknowEN': {
            'name': 'booknow',
            'url': ''
        },
        'facebook': {
            'url':''
        },
        'google+': {
            'url':''
        },
        'twitter': {
            'url':''
        },
        'instagram': {
            'url': ''
        },
        'tripadvisor': {
            'url': ''
        }, 
        'googlePlaceID': {
            'url': ''
        }
        
    })

    // Todo: Add some type checking
    let dealData = {
        'lang': '',
        'title': '',
        'name': '',
        'description': '',
        'shortdesc': ''

    }
    const [dealDataEN, setDealDataEN] = useState({ ...dealData, 'lang': 'en' })
    const [dealDataHR, setDealDataHR] = useState({ ...dealData, 'lang': 'hr' })

    const [address, setAddress] = useState({
        string: '',
        city: '',
        postal_code: '',
        country_code: '',
        geoLocation: {
            long: '',
            lat: ''
        }
    })

    let price = { currency: '', userType: 'any', value: null, description: '' }
    const [priceEUR, setPriceEUR] = useState({ ...price, currency: 'EUR', priceType: "FULL", priceConditions: [] })
    const [priceHRK, setPriceHRK] = useState({ ...price, currency: 'HRK', priceType: "FULL", priceConditions: [] })

    let discount = { currency: '', userType: 'any', discountType: 'trade', value: null, description: '' }
    const [discountHRK, setDiscountHRK] = useState({ ...discount, currency: 'EUR' })
    const [discountEUR, setDiscountEUR] = useState({ ...discount, currency: 'HRK' })




    const [dealPartner, setDealPartner] = useState({ slug: '', _id: '' })
    const [partners, setPartners] = useState([])

    const [uploadFiles, setUploadFiles] = useState({})
    const [fetching, setFetching] = useState(false)
    const [flashMessage, setFlashMessage] = useState(false)

    useEffect(() => {
        setFetching(true)
        if (dealID) { // If dealID exist first getDeal and then get deal's partner
            API.deal.getDeal(destinationID, dealID).then((deal) => {

                // #1.1 Load Deal
                setAddress({ ...address, ...deal.address })
                setCategories([...deal.categories])
                setGallery([...deal.gallery])
                setVideo(deal.video)
                setStatus(deal.status)
                setSlug(deal.slug)
                setName(deal.name)

                if (deal.coverImage)
                    setCoverImage(deal.coverImage)

                setExternalLink({ ...externalLink, ...deal.externalLink })

                if (deal.dealData) {
                    deal.dealData.forEach(data => {
                        if (data.lang === 'hr') setDealDataHR(data)
                        if (data.lang === 'en') setDealDataEN(data)
                    })
                }

                if (deal.workingHours) {
                    const workingHours = {}
                    deal.workingHours.forEach(data => {
                        workingHours[data.day] = data.time
                    })
                    setWorkingHours(workingHours)
                }

                if (deal.price) {
                    deal.price.forEach(data => {
                        if (data.userType !== 'any') return
                        if (data.currency === 'HRK') setPriceHRK({ ...priceHRK, ...data })
                        if (data.currency === 'EUR') setPriceEUR({ ...priceEUR, ...data })
                    })
                }

                if (deal.discount) {
                    deal.discount.forEach(data => {
                        if (data.userType !== 'any') return
                        if (data.currency === 'HRK') setDiscountHRK(data)
                        if (data.currency === 'EUR') setDiscountEUR(data)
                    })
                }

                // #1.2 Load Partner
                API.partner.getPartner(destinationID, deal.partnerID).then((dealPartner) => {
                    const { _id, slug } = dealPartner
                    setDealPartner({ _id, slug })
                    setFetching(false)
                }).catch(error => console.log(error))

            }).catch(error => console.log(error))
        }
        else { // Else get all partners
            API.partner.getPartners(destinationID).then((partners) => {

                setPartners(partners.map(({ _id, slug }) => { return { _id, slug } }))
                setFetching(false)
            }).catch(error => console.log(error))
        }
    }, [])


    const handleSubmit = async () => {
        setFetching(true)
        const { destinationID, dealID } = match.params

        let dayHours = []
        for (var day in workingHours)
            dayHours.push({ day, time: workingHours[day] })


        const deal = {
            name,
            slug: toSlug(name),
            categories,
            discount: [discountEUR, discountHRK],
            price: [priceHRK, priceEUR],
            address,
            dealData: [dealDataEN, dealDataHR],
            workingHours: dayHours,
            video,
            gallery,
            files: uploadFiles,
            coverImage,
            uploadCoverImage,
            partnerID: dealPartner._id,
            destinationID,
            externalLink,
            status
        }

        var re = new RegExp("^(http|https)://", "i");
        if(externalLink.booknowHR.url && !re.test(externalLink.booknowHR.url)) {
            alert("Book now HR link should start with http:// or https://")
            setFetching(false)
            return
        }
        if(externalLink.booknowEN.url && !re.test(externalLink.booknowEN.url)) {
            alert("Book now EN link should start with http:// or https://")
            setFetching(false)
            return
        }


        console.log("FFFFFFFFSSSSS")
        if (dealID) {
            const dealRes = await API.deal.updateDeal(destinationID, dealID, deal)
            // TODO: FLASH MESSAGE
            //    setFlashMessage({success: true, msg: "Save success"})
            alert("Save success")
            if (dealRes._id) history.go()
        }
        else {
            const dealRes = await API.deal.createDeal(destinationID, deal)
            if (dealRes._id) {
                const { url } = match
                history.push(url.replace("/newDeal", ""))
            }
        }
        setFetching(false)
    }


    const handleDelete = async () => {
        setFetching(true)
        const { destinationID, dealID } = match.params
        // Todo: Handle with dialogg
        const res = await API.deal.deleteDeal(destinationID, dealID)

        if (res.ok === 1) {
            const { url } = match
            history.push(url.replace(`/${dealID}`, ""))
        } else {

        }

        setFetching(false)
    }

    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="dealsItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="dealsBack" onClick={() => props.history.goBack()}>Back</div>
                        </div>
                        <div className="dashboard__title">{match.params.dealID ? "Deal Edit" : "Deal Create"}</div>
                    </div>
                    <div className="form-wrap">
                        <form className="form">
                            <div className="form__item">
                                <label htmlFor="name">Name*</label>
                                <input className="name" type="text" name="slug" placeholder="Enter deal name" required="required" value={name} onChange={e => setName(e.target.value)} />
                            </div>

                            <DealAddPartnerForm dealID={dealID} dealPartner={dealPartner} partners={partners} handleChange={setDealPartner} />

                            <div className="form__item">
                                <label htmlFor="status">Deal status</label>
                                <select name="status" required="required" onChange={e => setStatus(e.target.value)} value={status}>
                                    <option value="INACTIVE">Inactive</option>
                                    <option value="ACTIVE">Active</option>
                                </select>
                            </div>


                            <DealCategoriesForm categories={categories} handleChange={setCategories} />

                            <CoverImageForm coverImg={coverImage} uploadCoverImage={uploadCoverImage} handleUploadChange={setUploadCoverImage} handleChange={setCoverImage} />

                            {/* <div className="form__item">
                                <div className="formWrap">
                                    <div className="form__item">
                                        <label htmlFor="booknow">Book now HR</label>
                                        <input className="booknow" type="text" name="booknow" placeholder="Book now link" required="required" value={externalLink.booknowHR.url} onChange={e => setExternalLink({ ...externalLink, booknowHR: { ...externalLink.booknowHR, url: e.target.value } })} />
                                    </div>
                                    <div className="form__item">
                                        <label htmlFor="booknow">Book now EN</label>
                                        <input className="booknow" type="text" name="booknow" placeholder="Book now link" required="required" value={externalLink.booknowEN.url} onChange={e => setExternalLink({ ...externalLink, booknowEN: { ...externalLink.booknowEN, url: e.target.value } })} />
                                    </div>
                                </div>
                            </div> */}

                            <BookNowLink externalLink={externalLink} handleChange={setExternalLink} />


                            <div className="form__item">
                                <DealDataForm dealData={dealDataHR} handleChange={setDealDataHR} />
                            </div>
                            <div className="form__item">
                                <DealDataForm dealData={dealDataEN} handleChange={setDealDataEN} />
                            </div>







                            <div className="form__item">
                                <div className="formWrap">
                                    <DealPriceForm price={priceHRK} handleChange={setPriceHRK} />
                                    <DealPriceForm price={priceEUR} handleChange={setPriceEUR} />
                                </div>
                            </div>


                            <div className="form__item">
                                <DealDisountForm discount={discountHRK} handleChange={setDiscountHRK} />
                            </div>

                            <div className="form__item">
                                <DealDisountForm discount={discountEUR} handleChange={setDiscountEUR} />
                            </div>


                            <DealAddressForm address={address} handleChange={setAddress} />

                            <MetaDataForm externalLink={externalLink} handleChange={setExternalLink}/>

                            <DealGalleryForm gallery={gallery} handleChange={setGallery} uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} />

                            {/* <div className="form__item">
                                <div className="form__field-title">Video</div>
                                <div className="form__field">
                                    <div className="form__item">
                                        <label htmlFor="deal-video">Deal Video</label>
                                        <input className="deal-video" type="text" name="deal-video" placeholder="Enter url for deal video" value={video} onChange={e => setVideo(e.target.value)} />
                                    </div>
                                </div>
                            </div> */}




                            <div className="form__item form__item--mb50">
                                <div className="button-wrap">
                                    <div className="btn btn--red btn--fill" id="dealDelete" onClick={handleDelete}>Delete Deal</div>
                                    <input className="btn btn--green btn--fill" value="Save" onClick={handleSubmit} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


const BookNowLink = ({externalLink, handleChange})=>{


    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...externalLink, [name]: { ...externalLink[name], url: value }})
    }

    return(<div className="form__item">
    <div className="formWrap">
        <div className="form__item">
            <label htmlFor="booknow">Book now HR</label>
            <input className="booknow" type="text" name="booknowHR" placeholder="Book now link" required="required" value={externalLink.booknowHR.url} onChange={inputChange} />
        </div>
        <div className="form__item">
            <label htmlFor="booknow">Book now EN</label>
            <input className="booknow" type="text" name="booknowEN" placeholder="Book now link" required="required" value={externalLink.booknowEN.url} onChange={inputChange} />
        </div>
    </div>
</div>)
}



// Done !
const DealAddPartnerForm = ({ dealID, dealPartner, partners, handleChange }) => {


    const Options = partners.map(partner => <option key={partner._id} value={partner._id} >{partner.slug}</option>)
    const optionChanged = e => {
        const partner = partners.find(partner => partner._id === e.target.value)
        handleChange(partner)
    }


    if (dealID) {
        return (<div className="form__item">
            <label htmlFor="partner">Deal Partner</label>
            <input className="partner" type="text" name="partner" disabled={true} value={dealPartner.slug} readonly />
        </div>)
    }
    else {
        return (<div className="form__item">
            <label htmlFor="partner-list">Deal Partner</label>
            <select name="partner-list" required="required" onChange={optionChanged} >
                <option value="null" >Select Partner</option>
                {Options}
            </select>
        </div>)
    }


}
// Done !
const DealCategoriesForm = ({ handleChange, categories }) => {

    const inputChange = (event) => {
        const { name, checked } = event.target

        if (checked) {
            categories.push(name)
        }
        else {
            const index = categories.indexOf(name);
            if (index > -1) {
                categories.splice(index, 1);
            }
        }

        handleChange([...categories])
    }

    const isChecked = (name) => {
        return categories.includes(name)
    }

    return (<div className="form__item">
        <label htmlFor="category">Deal Category*</label>
        <ul>
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Food &amp; Drink" name="food-drink" id="food-drink" checked={isChecked("food-drink")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Food & Drink</span>
                </label>
            </li>
            <li>
                <label className="checkbox-label" style={{ marginTop: "10px" }}>
                    <input type="checkbox" value="To Sleep" name="to-sleep" id="to-sleep" checked={isChecked("to-sleep")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">To Sleep</span>
                </label>
            </li>

            {/* DEPRECATED */}
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="National parks &amp; parks of nature" name="national-parks" id="national-parks" checked={isChecked("national-parks")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">National parks & parks of nature  <b>[DEPRECATED use Attractions instead]</b></span>
                </label>
            </li>

            {/* DEPRECATED */}
            <li>
                <label className="checkbox-label" style={{ marginTop: "10px" }}>
                    <input type="checkbox" value="Unesco" name="unesco" id="unesco" checked={isChecked("unesco")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Unesco <b>[DEPRECATED use Attractions instead]</b></span>
                </label>
            </li>

            {/* DEPRECATED */}
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Thematic tours" name="thematic-tours" id="thematic-tours" checked={isChecked("thematic-tours")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Thematic tours <b>[DEPRECATED use Excursions instead]</b></span>
                </label>
            </li>

            <li>
                <label className="checkbox-label" style={{ marginTop: "10px" }}>
                    <input type="checkbox" value="Attractions" name="attractions" id="Attractions" checked={isChecked("attractions")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Attractions</span>
                </label>
            </li>

            {/* FRONTED RENAMED */}
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Local production" name="local-production" id="local-production" checked={isChecked("local-production")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Local products &amp; souvenirs</span>
                </label>
            </li>
            {/* DEPRECATED */}
            <li>
                <label className="checkbox-label" style={{ marginTop: "10px" }}>
                    <input type="checkbox" value="Sport &amp; adventure" name="sport-adventure" id="sport-adventure" checked={isChecked("sport-adventure")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Sport &amp; adventure <b>[DEPRECATED use Sport &amp; Health instead]</b></span>
                </label>
            </li>

            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Entertainment" name="entertainment" id="entertainment" checked={isChecked("entertainment")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Entertainment</span>
                </label>
            </li>
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Health &amp; SPA" name="health-spa" id="health-spa" checked={isChecked("health-spa")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Health &amp; SPA <b>[DEPRECATED use Sport &amp; Health instead]</b></span>
                </label>
            </li>

            {/* In new version 

                we added 
                "excursions", 
                "sport-health",
                "transport"
            */}

            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Excursions" name="excursions" id="excursions" checked={isChecked("excursions")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Excursions</span>
                </label>
            </li>
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Sport &amp; Health" name="sport-health" id="sport-health" checked={isChecked("sport-health")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Sport &amp; Health</span>
                </label>
            </li>
            <li>
                <label className="checkbox-label">
                    <input type="checkbox" value="Transport" name="transport" id="transport" checked={isChecked("transport")} onChange={inputChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Transport</span>
                </label>
            </li>



        </ul>
    </div>)
}
// Done
const DealDataForm = ({ handleChange, dealData }) => {
    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...dealData, [name]: value })
    }

    const descriptionChange = (value) => {
        handleChange({ ...dealData, description: value.toString('html') })
        setEditorState(value)
    }

    let description
    if (dealData.description) {
        description = RichTextEditor.createValueFromString(dealData.description.toString(), 'html')
    } else {
        description = RichTextEditor.createValueFromString("", 'html')
    }

    const [editorState, setEditorState] = React.useState(description);


    return (<Fragment>
        <div className="form__field-title">{`Details (${dealData.lang.toUpperCase()})`}</div>
        <div className="form__field">
            <div className="form__item">
                <label htmlFor="title">Deal title*</label>
                <input className="title" type="text" name="title" placeholder="Enter deal title" required="required" value={dealData.title} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="title">Venue Name*</label>
                <input className="name" type="text" name="name" placeholder="Enter venue name" required="required" value={dealData.name} onChange={inputChange} />
            </div>

            <div className="form__item">
                <label htmlFor="shortdesc">Short Description*</label>
                <textarea className="discount-shortdesc" type="text" name="shortdesc" value={dealData.shortdesc} placeholder="Enter shortdesc" onChange={inputChange} />
            </div>
        </div>

        <div className="form__item" style={{ width: "100%", minHeight: "200px" }}>
            <label htmlFor="description-hr" style={{ color: "blue", fontSize: "15px" }}>About xxx </label>
            <RichTextEditor value={editorState} onChange={descriptionChange} style={{ width: "100%" }} />
        </div>

    </Fragment>

    )

}
// Done!
const DealAddressForm = ({ handleChange, address }) => {
    const inputChange = (event) => {
        const { name, value } = event.target

        if (name === 'lat' || name === 'long')
            handleChange({ ...address, geoLocation: { ...address.geoLocation, [name]: value } })
        else
            handleChange({ ...address, [name]: value })
    }

    return (<div>
        <div className="form__field-title">Address</div>
        <div className="form__field">
            <div className="form__item">
                <label htmlFor="street">Street</label>
                <input className="street" type="text" name="street" placeholder="Enter street" value={address.street} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="city">City</label>
                <input className="city" type="text" name="city" placeholder="Enter city" value={address.city} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="postal-code">Postal Code</label>
                <input className="postal" type="text" name="postal_code" placeholder="Enter postal code" value={address.postal_code} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="country-code">Country Code</label>
                <input className="country" type="text" name="country_code" placeholder="Enter country code" value={address.country_code} onChange={inputChange} />
            </div>
            <div className="form__item">
                <div className="formWrap">
                    <div>
                        <div className="form__item">
                            <label htmlFor="long">Geo Location (Longitude)</label>
                            <input className="long" type="text" name="long" placeholder="Enter deal's longitude" value={address.geoLocation.long} onChange={inputChange} />
                        </div>
                    </div>
                    <div>
                        <div className="form__item">
                            <label htmlFor="geolocation-lat">Geo Location (Latitude)</label>
                            <input className="lat" type="text" name="lat" placeholder="Enter deal's latitude" value={address.geoLocation.lat} onChange={inputChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}



// TODO
const DealPriceForm = ({ handleChange, price }) => {

    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...price, value: parseFloat(value) })
    }

    const descriptionChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...price, description: value })
    }

    const onPriceTypeChange = (event) => {
        console.log("onPriceTypeChange", event.target.value)
        handleChange({ ...price, priceType: event.target.value })
    }
    const onPriceConditionsChange = (event) => {
        const { name, checked } = event.target

        if (checked) {
            price.priceConditions.push(name)
        }
        else {
            const index = price.priceConditions.indexOf(name);
            if (index > -1) {
                price.priceConditions.splice(index, 1);
            }
        }
        handleChange({ ...price, priceConditions: [...price.priceConditions] })
    }

    const isChecked = (condition) => {
        return price.priceConditions.includes(condition)
    }
    return (<div>
        <div className="form__field-title">{`Price (${price.currency})`}</div>
        <div className="form__field">
            <div className="form__item">
                <label htmlFor="price">Price*</label>
                <input className="price" type="text" value={price.value} name="price" placeholder="Default Price" required="required" onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="price">Price type </label>

                <span>Full price     <input className="price-type" type="radio" value="FULL" name={`FULL-${price.currency}`} checked={price.priceType === "FULL"} onChange={onPriceTypeChange} /></span>
                <span> Average spend <input className="price-type" type="radio" value="AVERAGE" name={`AVERAGE-${price.currency}`} checked={price.priceType === "AVERAGE"} onChange={onPriceTypeChange} /></span>
            </div>
            <div className="form__item">
                <label htmlFor="price-description">Description</label>
                <input className="price-description" type="text" name="description" value={price.description} placeholder="Enter price description" onChange={descriptionChange} />
            </div>
            <div className="form__item">
                <label htmlFor="category">Price Conditions </label>
                <ul>
                    <li>
                        <label className="checkbox-label">
                            <input type="checkbox" value="PERSON" name="PERSON" id="PERSON" checked={isChecked("PERSON")} onChange={onPriceConditionsChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Person</span>
                        </label>
                    </li>
                    <li>
                        <label className="checkbox-label" style={{ marginTop: "10px" }}>
                            <input type="checkbox" value="PERIOD" name="PERIOD" id="PERIOD" checked={isChecked("PERIOD")} onChange={onPriceConditionsChange} /><span className="checkbox-custom rectangular"></span><span className="checkbox-span">Period</span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>)

}

// TODO
const DealDisountForm = ({ handleChange, discount }) => {
    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...discount, value: parseFloat(value) })
    }

    const optionChanged = e => {
        handleChange({ ...discount, discountType: e.target.value })
    }


    const descriptionChange = (value) => {
        handleChange({ ...discount, description: value.toString('html') })
        setEditorState(value)
    }

    let description
    if (discount.description) {
        description = RichTextEditor.createValueFromString(discount.description.toString(), 'html')
    } else {
        description = RichTextEditor.createValueFromString("", 'html')
    }

    const [editorState, setEditorState] = React.useState(description);

    const { currency, discountType } = discount
    return (<Fragment>
        <div className="form__field-title">{`Deal Discount (${discount.currency === 'EUR' ? "EN" : "HR"})`}</div>
        <div className="form__field">
            <div className="formWrap">

                <div className="form__item">
                    <label htmlFor="discount">Deal Discount Amount*</label>
                    <input className="discount" type="text" value={discount.value} name="discount" placeholder="Enter deal discount" required="required" onChange={inputChange} />
                </div>
                <div className="form__item">
                    <label htmlFor="price-type">Deal Discount Price Type*</label>
                    <select name="price-type" required="required" onChange={optionChanged} value={discountType}>
                        <option value="trade" selected="selected">Trade (%)</option>
                        <option value="cash">{`Cash (${currency})`}</option>
                        <option value="gift">Gift</option>
                        <option value="free">Free</option>
                    </select>
                </div>
            </div>

        </div>
        <div className="form__item" style={{ width: "100%" }}>
            <label htmlFor="discount-description" style={{ color: "blue", fontSize: "15px" }}>About this deal discount</label>
            {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
            <RichTextEditor value={editorState} onChange={descriptionChange} style={{ width: "100%" }} />
            {/* <textarea className="discount-description" type="text" name="description" value={discount.description} placeholder="Enter text about the deal" onChange={descriptionChange} /> */}
        </div>

    </Fragment>)
}




const DealGalleryForm = ({ handleChange, gallery, uploadFiles, setUploadFiles }) => {

    const [countInputs, setCountInputs] = useState(1)


    const fileInputsRef = [];
    const setFileInputRef = element => {
        fileInputsRef.push(element);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        const rest = { ...uploadFiles, [name]: file }

        // debugger;
        setUploadFiles(rest);
    }

    const InputItems = []
    const createFileInputElement = index => {
        return (<div key={index} className="form__item">
            <label htmlFor="gallery-img1">Choose a gallery image</label>
            <input ref={setFileInputRef} className="gallery-img1" type="file" name={`gallery${index}`} accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
        </div>)
    }

    for (let i = 0; i < countInputs; i++)
        InputItems.push(createFileInputElement(i + 1))


    const GalleryItems = gallery.map((img) => {
        return (<div className="form__item">
            <div className="formWrap">
                <div>
                    <div className="form__item">
                        <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${img.path}`} alt={`${img.name}`} />
                    </div>
                </div>
                <div>
                    <div className="form__item">
                        <div className="btn btn--red btn--fill" id="" onClick={() => { removeImage(img._id) }}>Remove img</div>
                    </div>
                </div>
            </div>
        </div>)
    });


    const addMore = () => {
        if (countInputs + gallery.length >= 5) {
            alert("Each partner is limted to 5 images for now.")
            return
        }
        setCountInputs(countInputs + 1);
    }

    const removeImage = (_id) => {
        handleChange(gallery.filter((img) => { if (img._id !== _id) return img }))
    }




    return (<div className="form__item">
        <div className="form__field-title">Gallery</div>
        <div className="form__field">
            {GalleryItems}
            {InputItems}
            <div className="form__item">
                <div className="btn btn--green btn--fill" onClick={addMore}>Add more images</div>
            </div>
        </div>
    </div>)
}




const CoverImageForm = ({ coverImg, uploadFile, handleChange, handleUploadChange }) => {

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        handleUploadChange(file)

    }
    const removeImage = (_id) => {
        handleChange(null)
    }


    return (<div className="form__item">
        <div className="form__field-title">Cover image</div>
        {coverImg ? <div className="formWrap">
            <div className="form__item">
                <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${coverImg.path}`} alt={`${coverImg.name}`} />
            </div>
            <div className="form__item">
                <div className="btn btn--red btn--fill" onClick={() => { removeImage() }} >Remove</div>
            </div>
        </div>
            : <div className="form__field">
                <div className="form__item">
                    <label htmlFor="gallery-img1">Choose image</label>
                    <input className="gallery-img1" type="file" name="cover-image" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                </div>
            </div>}
    </div>)
}


const MetaDataForm = ({externalLink, handleChange}) =>{

    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...externalLink, [name]: {url: value}})
    }


    return(
        <div>
        <div className="form__field-title">MetaData</div>
        <div className="form__field">
            <div className="form__item">
                <div className="formWrap">
                    <div>
                        <div className="form__item">
                            <label htmlFor="facebook">Facebook</label>
                            <input className="facebook" type="text" name="facebook" placeholder="Enter facebook link" value={externalLink.facebook.url} onChange={inputChange} />
                        </div>
                    </div>
                    <div>
                        <div className="form__item">
                            <label htmlFor="google+">Google+</label>
                            <input className="google+" type="text" name="google+" placeholder="Enter Google+ link" value={externalLink["google+"].url} onChange={inputChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form__item">
                <div className="formWrap">
                    <div>
                        <div className="form__item">
                            <label htmlFor="twitter">Twiter</label>
                            <input className="twitter" type="text" name="twitter" placeholder="Enter Twiter link" value={externalLink.twitter.url} onChange={inputChange} />
                        </div>
                    </div>
                    <div>
                    <div className="form__item">
                            <label htmlFor="instagram">Instagram</label>
                            <input className="instagram" type="text" name="instagram" placeholder="Enter Instagram link" value={externalLink.instagram.url} onChange={inputChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form__item">
                <div className="formWrap">
                    <div>
                       <div className="form__item">
                            <label htmlFor="tripadvisor">Tripadvisor</label>
                            <input className="tripadvisor" type="text" name="tripadvisor" placeholder="Enter tripadvisor link" value={externalLink.tripadvisor.url} onChange={inputChange} />
                        </div>
                    </div>
                    <div>
                        <div className="form__item">
                            <label htmlFor="googlePlaceID">Google Places</label>
                            <input className="googlePlaceID" type="text" name="googlePlaceID" placeholder="Enter googlePlaceID" value={externalLink.googlePlaceID.url} onChange={inputChange} />
                        </div>
                    </div>
                </div>
            </div>

         
         
         
        </div>
    </div>
    )


}



/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

// Done!
const DealWorkingHoursForm = ({ handleChange, workingHours }) => {

    const inputChange = (event) => {
        const { name, value } = event.target
        handleChange({ ...workingHours, [name]: value })
    }
    return (<div className="form__field">
        <div className="form__field-title">Working Hours</div>
        <div className="form__field">
            <div className="form__item">
                <label htmlFor="M">Monday</label>
                <input className="days" type="text" name="M" placeholder="HH:MM - HH:MM" value={workingHours.M} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="T">Tuesday Start</label>
                <input className="days" type="text" name="T" placeholder="HH:MM - HH:MM" value={workingHours.T} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="W">Wednesday Start</label>
                <input className="days" type="text" name="W" placeholder="HH:MM - HH:MM" value={workingHours.W} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="R">Thursday Start</label>
                <input className="days" type="text" name="R" placeholder="HH:MM - HH:MM" value={workingHours.R} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="F">Friday Start</label>
                <input className="days" type="text" name="F" placeholder="HH:MM - HH:MM" value={workingHours.F} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="S">Saturday Start</label>
                <input className="days" type="text" name="S" placeholder="HH:MM - HH:MM" value={workingHours.S} onChange={inputChange} />
            </div>
            <div className="form__item">
                <label htmlFor="U">Sunday Start</label>
                <input className="days" type="text" name="U" placeholder="HH:MM - HH:MM" value={workingHours.U} onChange={inputChange} />
            </div>
        </div>
    </div>
    )
}

const ControlsForm = ({ partnerID, handleSubmit, handleDelete }) => {

    return (<div className="button-wrap">
        {
            partnerID ? <Fragment>
                <div className="btn btn--red btn--fill" id="partnerDelete" onClick={handleDelete} >Delete Partner</div>
                {/* {type="submit"} */}
                <div className="btn btn--green btn--fill" onClick={handleSubmit}> Save </div>
            </Fragment> : <Fragment>
                    {/* {type="submit"} */}
                    <div className="btn btn--green btn--fill" onClick={handleSubmit}> Create </div>
                </Fragment>
        }

    </div>)
}
