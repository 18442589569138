import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import TripPlannerItem from './TripPlannerItem' 
import TripPlannerList from './TripPlannerList' 


export default (props) => {
    const {match, user} = props;

    return(<Switch>
        {/* path: /admins/:destinationID/trip-planner */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <TripPlannerList {...props } /> }></Route> 
        
        {/* path: /admins/:destinationID/trip-planner/new */ }
        <Route exact path={`${match.path}/new`} render = {(props)=> <TripPlannerItem  {...props }/> }></Route>

        {/* path: /admins/:destinationID/trip-planner/:tripPlannerID */ }
        <Route path={`${match.path}/:tripPlannerID`} render = {(props)=> <TripPlannerItem  {...props }/> }></Route>
    </Switch>)
}