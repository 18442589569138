/* IQM-PASS API Specification */
let url = ''
if (process.env.NODE_ENV == 'development')
    url = "http://localhost:3000"
else if (process.env.NODE_ENV == 'production')
    url = "https://api.iqmpass.com"

url = "https://api.iqmpass.com"

const API_URL = url;
const SERVICE_ROUTE = "/api/partner";

const getToken = () => {
    return localStorage.getItem('partner_token');
}

const setToken = (token) => {
    return localStorage.setItem('partner_token', token);
}

const POST_OPTS = ContentType => {
    return {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}


const POST_OPTS_M = ContentType => {
    return {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${getToken()}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}


const authorise = async () => {
    const token = getToken();
    if (!token) return null;

    const options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    try {
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/authorise`, options);

        const { user } = await response.json();

        return user;
    } catch (error) {

        console.error(error);
        return null;
    }

}



const login = async ({ email, password }) => {

    if (!email || !password) throw new Error('Auth.loginAdmin wrong params.')

    const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, password }) // body data type must match "Content-Type" header
    }
    try {

        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/login`, options);
        const { user, token } = await response.json();

        if (token && user) {
            setToken(token)
            return user
        }
        else {
            return response
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}



const validatePass = async (code) => {
    try {
        const options = {
            body: JSON.stringify({ code }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/validatePass`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


export default {
    authorise,
    login,
    validatePass
}
