import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig


/* **************************************************************************************
 *                                  DEAL
 ************************************************************************************** */
/*
    Return [deals] where partnerID and destinationID 
*/
const getPartnerDeals = async (destinationID, partnerID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!partnerID) throw new Error('partnerID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, partnerID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPartnerDeals`, options);
        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
}

/*
    Return [deal] where destinationID 
*/
const getDeals = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getDeals`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


const getActiveDeals = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getActiveDeals`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


/*
    Return {deal} where dealID and destinationID 
*/
const getDeal = async (destinationID, dealID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!dealID) throw new Error('dealID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, dealID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getDeal`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


/*
    Return {deal} where dealID and destinationID 
*/
const createDeal = async (destinationID, payload) => {

    const { files, uploadCoverImage, ...deal } = payload;
    const data = new FormData();

    

    data.append('payload', JSON.stringify({ ...deal }))
    data.append('destinationID', destinationID)



    for (let image in files) {
        data.append('image', files[image])
    }

    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/createDeal`, options);
        const jsonRes = await response.json();
        console.log(jsonRes)
        return jsonRes
    } catch (error) {

        console.error(error);
        return null;
    }
}

const updateDeal = async (destinationID, dealID, payload) => {
    const { files, uploadCoverImage, ...deal } = payload;
    const data = new FormData();

    data.append('payload', JSON.stringify({ ...deal }))
    data.append('destinationID', destinationID)
    data.append('dealID', dealID)

    

    for (let image in files) {
        data.append('image', files[image])
    }

    if(uploadCoverImage) {
        data.append('cover-image', uploadCoverImage)
    }

    try {
        const options = {
            body: data,
            ...POST_OPTS_M()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateDeal`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}

const deleteDeal = async (destinationID, dealID) => {

    if (!dealID) throw new Error('dealID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, dealID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deleteDeal`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}









export default {
    getDeal,
    getDeals,
    getActiveDeals,
    getPartnerDeals,
    createDeal,
    updateDeal,
    deleteDeal
}