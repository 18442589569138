
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Admin from "./AdminDashboard/Admin";
import Partner from "./PartnerDashboard/Partner";

import PassValidation from "./PassValidation"

function App() {
  return (<Router>
    <Switch>
    <Route path = {"/"} exact component = {Home}></Route>
    <Route path = {"/pass/:code"} exact component = {PassValidation}></Route>  
    
    <Route path = {"/partner"} component = {Partner}></Route>
    <Route path = {"/admin"} component = {Admin}></Route>
    <Route component = {NF404} />
    </Switch>
  </Router>)
}

export default App;

const  NF404 = () => <h1> 404 Not found!</h1>


const Home = () => <div className="modal section" id="landing">
	<div className="modalWrap">
		<div className="modalBox-wrap">
			<a className="modalBox__logo" href="index">
				<img src="img/logos/iqm-logo.png" alt="IQM Destination" />
			</a>
			<div className="modalBox">
				<div className="modalBox__form">
					<div className="landing__title">Login as:</div>
					<div className="landing">
            <Link to={'/admin'}>
              <div className="landing__card"><span>Admin</span>
						  </div>
            </Link>
            <Link to={'/partner'}>
              <div className="landing__card"><span>Partner</span>
              </div>
            </Link>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>