import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import API from '../../network'


export default (props) => {
    const { match, user } = props;


    const [config, setConfig] = useState({})

    const [enableEntry, setEnableEntry] = useState(false)

    


    const makeEditable = (url) => {
        if (config[url]) {
            config[url].editable = true;
            setConfig({ ...config })
        }

    }

    const editConfig = (url, entry) => {
        if (config[url]) {
            config[url] = entry
            setConfig({ ...config })
        }
    }


    const addToConfig = (url, entry) => {
        console.log("addToConfig", url)
        if (config[url]) {
            alert(`URL ${url} allready exist! Please use edit on existing entry to modify it!`)
            return
        }
        else {
            config[url] = entry
            setConfig({ ...config })
            setEnableEntry(false)
        }
    }
    const removeFromConfig = (url) => {
        console.log("removeFromConfig", url, config[url])
        delete config[url]
        console.log("removeFromConfig", url, config[url])
        setConfig({ ...config })
    }


    

    const ConfigItems = []
    for (const url in config) {
        if (config[url].editable) {

            ConfigItems.push(<EntryComponent configData={{ url, entry: config[url] }} editConfig={editConfig} addToConfig={addToConfig} />)
        } else {
            ConfigItems.push(<tr className="config">
                <td>{url}</td>
                <td>{config[url].title}</td>
                <td>{config[url].canonical}</td>
                <td>{config[url].description}</td>
                <td>{config[url].keywords}</td>
                <td>
                    <div className="btn btn--yellow btn--fill" onClick={() => { makeEditable(url) }}>Edit</div>
                    <div className="btn btn--red btn--fill" onClick={() => { removeFromConfig(url) }}>Remove</div></td>
            </tr>)
        }


    }

    if (enableEntry) {
        ConfigItems.push(<EntryComponent addToConfig={addToConfig} />)
    }


    useEffect(()=>{
        API.destination.getWebConfig().then(webConfig =>{
            setConfig(webConfig)
        }).catch(e=>console.log(e))
    }, [])


    const saveConfiguration = () => {
        console.log("saveConfiguration", config)
        API.destination.saveWebConfig(config).then(response =>{
            if(response.error) {
                alert(response.error.message)
            }
            else {
                alert("Save success!")
            }
        })
    }


    return (
        <div className="dashboard section">
            <div className="main show" id="configuration">
                <div className="dashboard__main destination">
                    <div className="dashboard__row">

                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="itineraryBack" onClick={() => props.history.goBack()}>Back</div>
                        </div>
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--green" onClick={() => setEnableEntry(true)}> New Entry </div>
                        </div>

                    </div>

                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                                <tr className="pass-link" data-href="url:/">
                                    <th>URL</th>
                                    <th>Title</th>
                                    <th>Canonical</th>
                                    <th>Description</th>
                                    <th>Keywords</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ConfigItems}
                            </tbody>
                        </table>
                    </div>
                    <div className="table-wrap">
                        <div className="button-wrap">
                            <div className="btn btn--green btn--fill" onClick={saveConfiguration}>Save configuration</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

const EntryComponent = ({ configData, editConfig, addToConfig }) => {

    const inputStyle = {
        width: "100%",
        minHeight: "40px",
        fontSize: "14px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #f5f5f5",
        boxSizing: "border-box",
        padding: "0 12px",
        transition: "all 0.3s ease",
        position: "relative",
        zIndex: 2
    }

    const [url, setUrl] = useState("")
    const [entry, setEntry] = useState({
        title: "",
        canonical: "",
        keywords: "",
        description: ""
    })

    useEffect(()=>{
        if (configData) {
            setUrl(configData.url)
            setEntry(configData.entry)
        }
    },[])
    
    

    const inputChange = (event) => {
        const { name, value } = event.target
        setEntry({ ...entry, [name]: value })
    }

    const onAction = (url, entry) => {
        if (configData) {
            delete entry.editable
            editConfig(url, entry)
        }
        else {
            addToConfig(url, entry)
        }
    }

    return (
        <tr className="config">
            {configData ?
                <td><div className="form__item">{url}</div></td> :
                <td><div className="form__item"><input style={inputStyle} type="text" onChange={e => setUrl(e.target.value)} value={url} name="url" placeholder="URL" /></div></td>
            }
            <td><div className="form__item"><input style={inputStyle} type="text" onChange={inputChange} value={entry.title} name="title" placeholder="Enter title" /></div></td>
            <td><div className="form__item"><input style={inputStyle} type="text" onChange={inputChange} value={entry.canonical} name="canonical" placeholder="Enter canonical href" /></div></td>
            <td><div className="form__item"><input style={inputStyle} type="text" onChange={inputChange} value={entry.keywords} name="keywords" placeholder="Enter keywords" /></div></td>
            <td><div className="form__item"><input style={inputStyle} type="text" onChange={inputChange} value={entry.description} name="description" placeholder="Enter description" /></div></td>


            <td><div className="btn btn--yellow btn--fill" onClick={() => onAction(url, entry)}>Save</div></td>
        </tr>
    )


}

// useEffect(() => {
    //     setConfig(configH)
    // }, [])

    
// const configH = {
//     "/": {
//         "title": "IQM Pass - Experience more, save more - IQMpass.com",
//         "canonical": "https://iqmpass.com /"
//     },
//     "/hr/": {
//         "title": "IQM Pass - Doživite više, uštedite više - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/"
//     },
//     "/en/visit-golden-slavonia": {
//         "title": "IQM Pass - Visit Golden Slavonia - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-golden-slavonia",
//     },
//     "/en/visit-losinj": {
//         "title": "IQM Pass - Visit Lošinj - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-losinj"
//     },
//     "/en/visit-karlovac": {
//         "title": "IQM Pass - Visit Karlovac - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-karlovac"
//     },
//     "/en/visit-lika-destination": {
//         "title": "IQM Pass - Visit Lika Destination - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-lika-destination"
//     },
//     "/en/visit-vodice": {
//         "title": "IQM Pass - Visit Vodice - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-vodice"
//     },
//     "/hr/visit-golden-slavonia": {
//         "title": "IQM Pass - Posjetite Zlatnu Slavoniju - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-golden-slavonia"
//     },
//     "/hr/visit-losinj": {
//         "title": "IQM Pass - Posjetite Lošinj - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-losinj"
//     },
//     "/hr/visit-karlovac": {
//         "title": "IQM Pass - Posjetite Karlovac - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-karlovac"
//     },
//     "/hr/visit-lika-destination": {
//         "title": "IQM Pass - Posjetite Lika Destinaciju - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-lika-destination"
//     },
//     "/hr/visit-vodice": {
//         "title": "IQM Pass - Posjetite Vodice - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-vodice"
//     },
//     "/en/visit-golden-slavonia/deals": {
//         "title": "IQM Pass - Deals in Golden Slavonia - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-golden-slavonia/deals"
//     },
//     "/en/visit-losinj/deals": {
//         "title": "IQM Pass - Deals in Lošinj - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-losinj/deals"
//     },
//     "/en/visit-karlovac/deals": {
//         "title": "IQM Pass - Deals in Karlovac - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-karlovac/deals"
//     },
//     "/en/visit-lika-destination/deals": {
//         "title": "IQM Pass - Deals in Lika Destination - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-lika-destination/deals"
//     },
//     "/en/visit-vodice/deals": {
//         "title": "IQM Pass - Deals in Vodice - IQMpass.com",
//         "canonical": "https://iqmpass.com /en/visit-vodice/deals"
//     },
//     "/hr/visit-golden-slavonia/deals": {
//         "title": "IQM Pass - Ponude u Zlatnoj Slavoniji - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-golden-slavonia/deals"
//     },
//     "/hr/visit-losinj/deals": {
//         "title": "IQM Pass - Ponude na Lošinju - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-losinj/deals"
//     },
//     "/hr/visit-karlovac/deals": {
//         "title": "IQM Pass - Ponude u Karlovcu - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-karlovac/deals"
//     },
//     "/hr/visit-lika-destination/deals": {
//         "title": "IQM Pass - Ponude u Lika Destinaciji - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-lika-destination/deals"
//     },
//     "/hr/visit-vodice/deals": {
//         "title": "IQM Pass - Ponude u Vodicama - IQMpass.com",
//         "canonical": "https://iqmpass.com /hr/visit-vodice/deals"
//     },
// }

