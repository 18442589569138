import React, { useState, useEffect, Fragment } from 'react';


import API from '../../network'

import toSlug from '../../helpers/toSlug'

export default ({ destinationID, onTeaserChange, activeTeaser, deleteTeaser }) => {

  const [deals, setDeals] = useState([])
  const [partners, setPartners] = useState([])




  const [teaserDeals, setTeaserDeals] = useState([])
  const [teaserName, setTeaserName] = useState('')


  const saveTeaser = () => {
    activeTeaser = {
      view: false,
      teaser: {
        name: teaserName,
        slug: toSlug(teaserName),
        deals: teaserDeals.map(deal => deal._id)
      }
    }
    onTeaserChange(activeTeaser)
  }


  const removeTeaser = () => {
    deleteTeaser(activeTeaser.teaser)
  }


  const addToTeaserList = (deal) => {
    setTeaserDeals([deal, ...teaserDeals])
  }

  const removeFromTeaserList = (deal) => {

    const index = teaserDeals.indexOf(deal)
    if (index > -1) teaserDeals.splice(index, 1)
    setTeaserDeals([...teaserDeals])
  }

  useEffect(() => {

    API.deal.getDeals(destinationID).then(deals => {
      setDeals(deals)
      API.partner.getPartners(destinationID).then(partners => {
        setPartners(partners)

        if (activeTeaser.teaser) {
          setTeaserName(activeTeaser.teaser.name)
          setTeaserDeals(deals.filter((deal) => {
            if (activeTeaser.teaser.deals.find((id) => id === deal._id))
              return deal
          }))
        }
      }).catch(e => console.log(e))
    }).catch(e => console.log(e))

  }, [])

  return (
    <div className="dashboard section">
      <div className="main show" id="teaserItem">
        <div className="dashboard__main">
          <div className="dashboard__row">
            <div className="dashboard__btn">
              <div className="btn btn--fill btn--yellow" id="teaserBack" onClick={e => onTeaserChange({ view: false, teaser: null })}>Cancel</div>
            </div>
            <div className="dashboard__title">Teaser Edit</div>
          </div>


          <div className="table-wrap">
            <div className="table__title">Deals Included in Teaser</div>
            <TeaserDeals teaserDeals={teaserDeals} partners={partners} remove={removeFromTeaserList} />
            {/* Submit logic */}
            <div className="form">
              <div className="form__field">
                <div className="form__item">
                  {teaserDeals.length ? <Fragment>
                    <label htmlFor="teaser_name">Teaser Name</label>
                    {
                      activeTeaser.teaser ?
                        <input className="country" type="text" name="teaser_name" placeholder="Enter Teaser name" value={teaserName} readOnly disabled={true} /> :
                        <input className="country" type="text" name="teaser_name" placeholder="Enter Teaser name" value={teaserName} onChange={e => setTeaserName(e.target.value)} />
                    }
                  </Fragment> : null}
                </div>
                <div className="form__item">
                  <div className="button-wrap">
                    {teaserDeals.length ? <div className="btn btn--green btn--fill" onClick={saveTeaser}>{activeTeaser.teaser ? 'Save Teaser' : 'Create teaser'}</div> : null}
                    {teaserDeals.length && activeTeaser.teaser ? <div className="btn btn--red btn--fill" onClick={removeTeaser}> Delete Teaser</div> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-wrap">
            <div className="table__title">Deals List</div>
            <DealList deals={deals} teaserDeals={teaserDeals} partners={partners} add={addToTeaserList} />
          </div>
        </div>
      </div>
    </div>
  );
}

const TeaserDeals = ({ teaserDeals, partners, remove, activeTease }) => {

  const TeaserItems = teaserDeals.map(deal => {

    const getPartnerName = (partners) => {
      const partner = partners.find(partner => partner._id === deal.partnerID)
      return partner ? partner.slug : ''
    }
    const categoriesList = (categories) => {
      let categoryString = ''
      for (let i = 0; i < categories.length; i++)
        categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
      return categoryString
    }

    const priceHRK = deal.price.find(p => p.currency === 'HRK')
    const priceEUR = deal.price.find(p => p.currency === 'EUR')

    return (<tr className="deal-link" data-href="url:/">
      <td>{deal.slug}</td>
      <td>{deal.name}</td>
      <td>{getPartnerName(partners)}</td>
      <td>{categoriesList(deal.categories)}</td>
      <td>{`${deal.discount[0].value}%`}</td>
      <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
      <td>
        <div className="btn btn--red btn--fill" onClick={() => { remove(deal) }}>Remove</div>
      </td>
    </tr>)

  })

  return (
    <table className="table">
      <thead>
        <tr className="deal-link" data-href="url:/">
          <th>ID</th>
          <th>Name</th>
          <th>Partner Name</th>
          <th>Type</th>
          <th>Discount</th>
          <th>Price (Eur/Hrk)</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{TeaserItems}</tbody>
    </table>)
}


const DealList = ({ deals, teaserDeals, partners, add }) => {

  const DealItems = deals.map(deal => {
    const getPartnerName = (partners) => {
      const partner = partners.find(partner => partner._id === deal.partnerID)
      return partner ? partner.slug : ''
    }
    const categoriesList = (categories) => {
      let categoryString = ''
      for (let i = 0; i < categories.length; i++)
        categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
      return categoryString
    }

    const priceHRK = deal.price.find(p => p.currency === 'HRK')
    const priceEUR = deal.price.find(p => p.currency === 'EUR')

    const includes = teaserDeals.includes(deal)
    const rowClass = 'deal-link' + (includes ? ' table--paused' : '')

    return (<tr className={rowClass} data-href="url:/">
      <td>{deal.slug}</td>
      <td>{deal.name}</td>
      <td>{getPartnerName(partners)}</td>
      <td>{categoriesList(deal.categories)}</td>
      <td>{`${deal.discount[0].value}%`}</td>
      <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
      <td>
        {(!includes) ? <div className="btn btn--green btn--fill" onClick={() => { add(deal) }}>Add to list</div> : null}
      </td>
    </tr>)

  })
  return (
    <table className="table">
      <thead>
        <tr className="deal-link" data-href="url:/">
          <th>ID</th>
          <th>Name</th>
          <th>Partner Name</th>
          <th>Type</th>
          <th>Discount</th>
          <th>Price (Eur/Hrk)</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {DealItems}
      </tbody>
    </table>


  )
}