import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDropzone } from 'react-dropzone';
import Loading from '../../components/Loading'

import API from '../../network'
import toSlug from '../../helpers/toSlug'
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import RichTextEditor from 'react-rte';
import tripPlanner from '../../network/tripPlanner';

import DealsList from './components/DealsList';


import { Container as DNDContainer, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from 'array-move';


// TODO: 
// https://codesandbox.io/s/material-ui-sortable-list-with-react-smooth-dnd-swrqx?resolutionWidth=320&resolutionHeight=675

import FuzzySearch from "fuzzy-search";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";


/* Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";




import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/Delete';


import TripPlannerDeals from './components/TripPlannerDeals'
import CoverImageForm from './components/CoverImageForm'
import LangWebDataForm from './components/LangWebDataForm'
import Map from './components/Map'



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formPaper: {
        width: "100%",
        marginBottom: "15px",
        padding: theme.spacing(2)
    },
    container: {
        padding: 50
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    adornment: {
        marginRight: 0
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    button: {
        margin: theme.spacing(1),
    },

    controls: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexDirection: "row-reverse",
        margin: theme.spacing(2),
    },

}));


export default ({ match, history }) => {

    const classes = useStyles();

    const { destinationID, tripPlannerID } = match.params;
    const [fetching, setFetching] = useState(true);

    // Rethink this unsaved and preview .... and finish this tomorow
    const [unsaved, setUnsaved] = useState(false);
    const [previewLink, setPreviewLink] = useState("");

    /* TRIP PLANNER */
    const [name, setName] = useState("")
    const [slug, setSlug] = useState("")
    const [deals, setDeals] = useState([])
    const [coverImage, setCoverImage] = useState(null)
    const [uploadCoverImage, setUploadCoverImage] = useState(null)
    const [metaData, setMetaData] = useState({
        "hr": {
            "title": "",
            "subtitle": "",
            "description": "",
            "shortdesc": ""
        },
        "en": {
            "title": "",
            "subtitle": "",
            "description": "",
            "shortdesc": ""
        },
    })
    const [map, setMap] = useState(null)




    /* API DB DATA */
    const [dealList, setDealList] = useState([])
    const [partnerList, setPartnerList] = useState([])
    const [destination, setDestination] = useState(null)



    // TODO: Handle adding deals
    const LoadData = async () => {

        const getPartnerName = (partners, deal) => {
            const partner = partners.find(partner => partner._id === deal.partnerID)
            return partner ? partner.name : ''
        }
        try {
            setFetching(true)

            const dealList = await API.deal.getActiveDeals(destinationID)
            const partnerList = await API.partner.getPartners(destinationID)
            const destination = await API.destination.getDestination(destinationID)



            // This is big HaH! Basicly I am expanding curent deal object with parnter name and discountValue
            setDealList(dealList.map(deal => {
                const discount = deal.discount[0];
                const curencyMap = {
                    "HRK": "kn",
                    "EUR": "€"
                }
                var discountValue;
                if (deal.discount[0].discountType == deal.discount[1].discountType) {
                    if (deal.discount[0].discountType === 'cash') {
                        discountValue = `${deal.discount[0].value}${curencyMap[deal.discount[0].currency]} / ${deal.discount[1].value}${curencyMap[deal.discount[1].currency]}`
                    }
                    else if (deal.discount[0].discountType === 'trade') {
                        if (deal.discount[0].value == deal.discount[1].value) {
                            discountValue = `-${discount.value}%`;
                        } else {
                            discountValue = `${deal.discount[0].value}% / {deal.discount[1].value}%}`;
                        }
                    }
                    else if (deal.discount[0].discountType == 'gift') discountValue = "GIFT";
                    else discountValue = "FREE";
                }

                return {
                    ...deal,
                    partnerName: getPartnerName(partnerList, deal),
                    discountValue
                }
            }))
            setPartnerList(partnerList)
            setDestination(destination)

            if (tripPlannerID) {
                const tripPlanner = await API.tripPlanner.getTripPlanner(destinationID, tripPlannerID)
                setSlug(tripPlanner.slug)
                setName(tripPlanner.name)
                setCoverImage(tripPlanner.coverImage)
                setMetaData({ ...metaData, ...tripPlanner.metaData })
                setDeals(tripPlanner.deals)
                setMap(tripPlanner.map)
                setPreviewLink(`https://iqmpass.com/hr/${destination.webUrl}/trip-planner/${tripPlanner.slug}`);


            }
        } catch (error) {
            console.error("page->TripPlanerItem", error);
        } finally {
            setFetching(false)
            setUnsaved(false)
        }
    }

    useEffect(() => {
        try {
            LoadData()
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        setUnsaved(true);
    }, [name, slug, deals, coverImage, metaData, map])

    /* CRUD API TRIP-PLANNER */
    const handleSubmit = async () => {

        if(!name) {
            alert("Name property is required!");
            return;
        }

        setFetching(true)
        const tripPlanner = {
            name,
            slug: toSlug(slug),
            coverImage,
            metaData,
            deals,
            destinationID,
            map
        }

        if (tripPlannerID) {
            const responseJSON = await API.tripPlanner.updateTripPlanner(destinationID, tripPlannerID, { ...tripPlanner, uploadCoverImage })

            if (responseJSON.error) {
                console.log(responseJSON.error.message)
                // Handle differently
                alert(responseJSON.error.message)
            } else if (responseJSON._id) {
                console.log(responseJSON)
                alert("Save success")
                history.go()
            }
        }
        else {
            const responseJSON = await API.tripPlanner.createTripPlanner(destinationID, { ...tripPlanner, uploadCoverImage })

            if (responseJSON.error) {
                console.log(responseJSON.error.message)
                // Handle differently
                alert(responseJSON.error.message)
            } else if (responseJSON._id) {
                const { url } = match
                history.push(url.replace("/new", ""))
            }
        }
        setFetching(false)
    }
    const handleDelete = async () => {
        setFetching(true)
        const { destinationID, tripPlannerID } = match.params
        // Todo: Handle with dialogg
        const res = await API.tripPlanner.deleteTripPlanner(destinationID, tripPlannerID)

        if (res.ok === 1) {
            const { url } = match
            history.push(url.replace(`/${tripPlannerID}`, ""))
        } else {

        }
        setFetching(false)
    }

    const onFileChanged = (file) => {
        setUploadCoverImage(file)
        setCoverImage(null)
    }

    const addDeals = (dealIDList) => {
        setDeals([...deals, ...dealIDList])
    }

    const removeDeal = (dealID) => {
        setDeals([...deals.filter(id => id != dealID)])
    }
    const arangeDeals = (dealIDList) => {
        setDeals(dealIDList)
    }


    /*  Function */
    const filterDealsList = (dealsList, dealsIDList, exclude) => {
        const dealsList_f = [];

        dealsList.forEach(deal => {
            if (dealsIDList.includes(deal._id) && !exclude)
                dealsList_f.push(deal)
            else if (!dealsIDList.includes(deal._id) && exclude) {
                dealsList_f.push(deal)
            }
        })
        if (!exclude) {
            return dealsList_f.sort((a, b) => dealsIDList.indexOf(a._id) - dealsIDList.indexOf(b._id));
        } else {
            return dealsList_f;
        }
    };


    if (fetching) return <Loading />


    const PreviewLink = (previewLink) => {
        if (previewLink) {

            if (!unsaved)
                return <a className="btn btn--fill btn--green" href={previewLink} target="blank">Preview</a>
            else
                return <a className="btn btn--fill btn--green" target="blank" onClick={() => alert("Please save first")}>Preview</a>
        } else
            return null
    }

    return (
        <div className="dashboard section">
            <div className="main show" id="itineraryItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="itineraryBack" onClick={() => history.goBack()}>Back</div>
                        </div>
                        {
                            PreviewLink(previewLink)
                        }
                    </div>
                    <Container className={classes.container}>
                        <Grid className={classes.controls} item container xs={12}>
                            {tripPlannerID ?
                                <Button variant="outlined" color="secondary" onClick={handleDelete}>
                                    Remove
                                </Button> : null}
                            <Button variant="outlined" color="primary" onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                        {/* NAME AND SLUG */}
                        <Grid item container xs={12}>
                            <Paper className={classes.formPaper}>
                                <Grid item container xs={12}>
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="name">Trip Planner Name</InputLabel>
                                        <Input
                                            id="name"
                                            value={name}
                                            onChange={e => {
                                                setName(e.target.value)
                                                setSlug(toSlug(e.target.value))
                                            }}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classes.margin}>
                                        <InputLabel htmlFor="web-url-slug">Web URL</InputLabel>
                                        <Input
                                            id="web-url-slug"
                                            value={slug}
                                            onChange={e => {
                                                setSlug(e.target.value)
                                            }}
                                            startAdornment={<InputAdornment className={classes.adornment} position="start"><span style={{ color: "" }}>{`https://iqmpass.com/hr/${destination.webUrl}/trip-planner/`}</span></InputAdornment>}
                                        />
                                    </FormControl>
                                </Grid>
                            </Paper>
                        </Grid>
                        {/* COVER IMAGE */}
                        <Grid item container xs={12}>
                            <CoverImageForm acceptType="image/*"
                                setFile={onFileChanged}
                                file={coverImage || uploadCoverImage}
                            />
                        </Grid>
                        {/* WEB DATA LANG */}
                        <Grid item container xs={12}>
                            <LangWebDataForm metaData={metaData} lang="hr" handleChange={setMetaData} />
                            <LangWebDataForm metaData={metaData} lang="en" handleChange={setMetaData} />
                        </Grid>

                        <Grid item container xs={12}>
                            <Map dealList={filterDealsList(dealList, deals)} lang="hr" handleChange={setMap} map={map} />
                        </Grid>
                        {/* Destination Deal list */}
                        <Grid item container xs={12}>
                            <DealsList
                                onTransfer={addDeals}
                                dealList={filterDealsList(dealList, deals, true)}
                                side={"right"}
                                title={"Destination Deals"}
                            />
                        </Grid>
                        <Paper>
                            <Typography className={classes.heading} style={{ padding: 10 }}>{`Trip Planner Deals`}</Typography>
                            <TripPlannerDeals
                                removeDeal={removeDeal}
                                arangeDeals={arangeDeals}
                                dealList={filterDealsList(dealList, deals)}
                                title={"Trip Planner Deals"} />
                        </Paper>
                    </Container>
                </div>
            </div>
        </div>
    );
}





// const CoverImageForm = ({ coverImg, uploadFile, handleChange, handleUploadChange }) => {

//     const handleImageChange = (e) => {
//         const file = e.target.files[0];
//         handleUploadChange(file)

//     }
//     const removeImage = (_id) => {
//         handleChange(null)
//     }


//     return (<div className="form__item">
//         <div className="form__field-title">Cover image</div>
//         {coverImg ? <div className="formWrap">
//             <div className="form__item">
//                 <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${coverImg.path}`} alt={`${coverImg.name}`} />
//             </div>
//             <div className="form__item">
//                 <div className="btn btn--red btn--fill" onClick={() => { removeImage() }} >Remove</div>
//             </div>
//         </div>
//             : <div className="form__field">
//                 <div className="form__item">
//                     <label htmlFor="gallery-img1">Choose image</label>
//                     <input className="gallery-img1" type="file" name="cover-image" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
//                 </div>
//             </div>}
//     </div>)
// }

