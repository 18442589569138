import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


import ItineraryItem from './ItineraryItem'
import ItineraryList from './ItineraryList'

export default (props) => {
    const {match, user} = props;


    return(<Switch>
        {/* path: /admins/:destinationID/itinerary */ }
        <Route exact path={`${match.path}/`} render = {(props)=> <ItineraryList user={user} {...props }/> }></Route> 
        
        {/* path: /admins/:destinationID/itinerary/newItinerary */ }
        <Route exact path={`${match.path}/newItinerary`} render = {(props)=> <ItineraryItem  user={user} {...props}/> }></Route>

        {/* path: /admins/:destinationID/itinerary/:partnerID */ }
        <Route path={`${match.path}/:itineraryID`} render = {(props)=> <ItineraryItem  user={user} {...props}/> }></Route>
    </Switch>)
}