import React from "react";

export default  (props)=> {
    const modalStyle = {}
    if(!props.fullScreen)
        modalStyle.zIndex = 98;

        return <div style={modalStyle} className="modal section"><div className="loader"><div className="loaderOuter"><div className="loaderInner"><svg focusable="false" height="48" size="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="21.5"></circle></svg></div></div></div></div>
    
}
