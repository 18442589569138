import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


import Loading from '../../components/Loading'
import API from '../../network'
import toSlug from '../../helpers/toSlug'



export default (props) => {

    const { match, history } = props;
    const { destinationID } = match.params;


    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')
    const [coverImage, setCoverImage] = useState(null)
    const [videoURL, setVideoURL] = useState('')
    const [uploadCoverImage, setUploadCoverImage] = useState(null)


    const [webUrl, setWebUrl] = useState('')



    const [heroSection, setHeroSection] = useState({
        textData: {
            hr: {
                title: ''
            },
            en: {
                title: ''
            }
        },
        images: [],
        uploadImgFiles: [],
    })
    const [advantagesSection, setAdvantagesSection] = useState({
        textData: {
            hr: {
                title: '',
                description: ''
            },
            en: {
                title: '',
                description: ''
            }
        }
    })
    // const [pricesVariantsSection, setPricesVariantsSection] = useState({
    //     textData: {
    //         hr: {
    //             title: '',
    //             description: ''
    //         },
    //         en: {
    //             title: '',
    //             description: ''
    //         }
    //     }
    // })
    // const [videoSection, setVideoSection] = useState({
    //     textData: {
    //         hr: {
    //             title: '',
    //             description: ''
    //         },
    //         en: {
    //             title: '',
    //             description: ''
    //         }
    //     },
    //     video: null,
    //     uploadVideoFile: null
    // })
    const [aboutSection, setAboutSection] = useState({
        textData: {
            hr: {
                title: '',
                subtitle: '',
                description: ''
            },
            en: {
                title: '',
                subtitle: '',
                description: ''
            }
        },
        images: [],
        uploadImgFiles: [],
    })


    const [fetching, setFetching] = useState(false)

    useEffect(() => {

        setFetching(true)
        API.destination.getDestination(destinationID).then(destination => {
            if (destination.name)
                setName(destination.name)
            if (destination.webData)
                setWebUrl(destination.webUrl)
            if(destination.coverImage)
                setCoverImage(destination.coverImage)
            if(destination.videoURL)
                setVideoURL(destination.videoURL)

            if (destination.webData) {
                const { webData } = destination;

                for (const webSection in webData) {
                    switch (webSection) {
                        case 'hero_section':
                            setHeroSection({ ...heroSection, ...webData[webSection] })
                            break;
                        case 'advantages_section':
                            setAdvantagesSection({ ...advantagesSection, ...webData[webSection] })
                            break;
                        // case 'pricesVariants_section':
                        //     setPricesVariantsSection({ ...pricesVariantsSection, ...webData[webSection] })
                        //     break;
                        // case 'video_section':
                        //     setVideoSection({ ...videoSection, ...webData[webSection] })
                        //     break;
                        case 'about_section':
                            setAboutSection({ ...aboutSection, ...webData[webSection] })
                            break;
                        default:
                            console.error(`Uknown Section ->${webSection}`)
                    }



                }

            }

            setFetching(false)
        }).catch(e => console.log(e))
    }, [])


    const handleSubmit = async () => {
        setFetching(true)
        const destination = {
            name,
            slug: toSlug(name),
            webUrl,
            coverImage,
            uploadCoverImage,
            videoURL,
            webData: {
                'hero_section': heroSection,
                'advantages_section': advantagesSection,
                // 'pricesVariants_section': pricesVariantsSection,
                // 'video_section': videoSection,
                'about_section': aboutSection
            }
        }

        const destRes = await API.destination.updateDestination(destinationID, destination)
        debugger;
        if (destRes._id) history.go()


        setFetching(false)
    }

    if (fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="website">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__title">Website Edit</div>
                    </div>
                    <div className="form-wrap">
                        <form className="form">

                            <div className="form__item">
                                <label htmlFor="name">Destination Name</label>
                                <input className="name" type="text" name="name" placeholder="Enter destination name" required="required" value={name} onChange={e => setName(e.target.value)} />
                            </div>

                            <div className="form__item">
                                <label htmlFor="webUrl">Web address</label>
                                <input className="name" type="text" name="webUrl" placeholder="Enter web url" required="required" value={webUrl} onChange={e => setWebUrl(e.target.value)} />
                            </div>


                            <CoverImageForm coverImg={coverImage} uploadCoverImage={uploadCoverImage} handleUploadChange={setUploadCoverImage} handleChange={setCoverImage} />

                            <WebSection sectionTitle="Hero section" data={heroSection} handleChange={setHeroSection} />
                            <WebSection sectionTitle="Advantages section" data={advantagesSection} handleChange={setAdvantagesSection} />
                            {/* <WebSection sectionTitle="Prices & Variants section" data={pricesVariantsSection} handleChange={setPricesVariantsSection} /> */}
                            {/* <WebSection sectionTitle="Video section" data={videoSection} handleChange={setVideoSection} /> */}
                            <WebSection sectionTitle="About section" data={aboutSection} handleChange={setAboutSection} />
                            <VideoSection videoURL = {videoURL} handleChange = {setVideoURL}/>

                            <div className="form__item">
                                <div className="button-wrap">
                                    <div id="passDelete"></div><input className="btn btn--green btn--fill" value="Save" onClick={handleSubmit} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


const CoverImageForm = ({ coverImg, uploadFile, handleChange, handleUploadChange }) => {

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        handleUploadChange(file)

    }
    const removeImage = (_id) => {
        handleChange(null)
    }


    return (<div className="form__item">
        <div className="form__field-title">Cover image</div>
        {coverImg ? <div className="formWrap">
            <div className="form__item">
                <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${coverImg.path}`} alt={`${coverImg.name}`} />
            </div>
            <div className="form__item">
                <div className="btn btn--red btn--fill" onClick={() => { removeImage() }} >Remove</div>
            </div>
        </div>
            : <div className="form__field">
                <div className="form__item">
                    <label htmlFor="gallery-img1">Choose image</label>
                    <input className="gallery-img1" type="file" name="cover-image" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                </div>
            </div>}
    </div>)
}


const WebSection = ({ sectionTitle, data, handleChange }) => {

    const { textData, images, uploadImgFiles, video, uploadVideoFile } = data;

    console.log("WebSection", data)

    const handleTextDataChange = (textData) => {
        handleChange({ ...data, textData })
    }

    const handleImagesChange = (images) => {
        handleChange({ ...data, images })
    }
    const handleUploadImagesChange = (uploadImgFiles) => {
        handleChange({ ...data, uploadImgFiles })
    }

    const handleVideoChange = (video) => {
        handleChange({ ...data, video })
    }
    const handleUploadVideoChange = (videoFile) => {

        const { uploadVideoFile } = videoFile

        handleChange({ ...data, uploadVideoFile: { file: uploadVideoFile } })
    }

    const SectionTitleStyle = {
        fontSize: '20px',
        margin: 'auto',
        marginBottom: '10px',
        paddingBottom: '5px',
        fontWeight: '600',
        borderBottom: '1px solid #f2b83b'
    }

    const TopBorderStyle = {
        borderTop: '1px solid #f2b83b',
        paddingTop: '10px'
    }


    return (<div className="form__item" style={TopBorderStyle}>
        <div style={SectionTitleStyle} className="form__field-title">{sectionTitle}</div>
        <div className="form__field">


            {textData !== undefined ?
                <TextDataForm textData={textData} handleChange={handleTextDataChange} /> : null}

            {images !== undefined && uploadImgFiles !== undefined ?
                <ImagesForm images={images} uploadFiles={uploadImgFiles} handleChange={handleImagesChange} handleUploadChange={handleUploadImagesChange} />
                : null}

            {video !== undefined && uploadVideoFile !== undefined ?
                <VideoForm video={video} uploadFile={uploadVideoFile} handleChange={handleVideoChange} handleUploadChange={handleUploadVideoChange} />
                : null}

        </div>
    </div>)
}

const VideoSection = ({videoURL, handleChange})=>{

    const SectionTitleStyle = {
        fontSize: '20px',
        margin: 'auto',
        marginBottom: '10px',
        paddingBottom: '5px',
        fontWeight: '600',
        borderBottom: '1px solid #f2b83b'
    }

    const TopBorderStyle = {
        borderTop: '1px solid #f2b83b',
        paddingTop: '10px'
    }


    return (<div className="form__item" style={TopBorderStyle}>
        <div style={SectionTitleStyle} className="form__field-title">Video Section</div>
        <div className="form__field">

        <label htmlFor="title">{`Video URL`}</label>
        <input className="title" type="text" name="title" placeholder="Enter Title" value={videoURL} onChange={(e) => handleChange(e.target.value)} />
      

        </div>
    </div>)




}


const TextDataForm = ({ textData, handleChange }) => {
    const inputChanged = (lang, payload) => {

        textData[lang] = { ...textData[lang], ...payload }
        handleChange({ ...textData })
    }

    let textDataElements = []
    if (textData) {
        for (const lang in textData) {
            let { title, description, subtitle } = textData[lang]

            textDataElements.push(<div className="form__item">
                <div className="form__field-title">{lang.toUpperCase()}</div>
                <div className="form__field">

                    {title !== undefined ? <div className="form__item">
                        <label htmlFor="title">{`Section title (${lang.toUpperCase()})`}</label>
                        <input className="title" type="text" name="title" placeholder="Enter Title" value={title} onChange={(e) => inputChanged(lang, { title: e.target.value })} />
                    </div> : null}

                    {subtitle !== undefined ? <div className="form__item">
                        <label htmlFor="subtitle"> {`Section description (${lang.toUpperCase()})`}</label>
                        <input className="subtitle" type="text" name="subtitle" placeholder="Enter Subtitle" value={subtitle} onChange={(e) => inputChanged(lang, { subtitle: e.target.value })} />
                    </div> : null}

                    {description !== undefined ? <div className="form__item">
                        <label htmlFor="description"> {`Section description (${lang.toUpperCase()})`}</label>
                        <textarea className="description" cols="1" rows="2" name="description" placeholder="Enter Description" value={description} onChange={(e) => inputChanged(lang, { description: e.target.value })}></textarea>
                    </div> : null}
                </div>
            </div>)
        }
    }

    return (<Fragment>{textDataElements}</Fragment>)

}


const ImagesForm = ({ images, uploadFiles, handleChange, handleUploadChange }) => {

    const [countInputs, setCountInputs] = useState(1)


    const fileInputsRef = [];
    const setFileInputRef = element => {
        fileInputsRef.push(element);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        const rest = { ...uploadFiles, [name]: file }
        if (!file) { delete rest[name] }

        handleUploadChange(rest);
    }

    const InputItems = []
    const createFileInputElement = index => {
        return (<div key={index} className="form__item">
            <label htmlFor="gallery-img1">Choose image</label>
            <input ref={setFileInputRef} className="gallery-img1" type="file" name={`gallery${index}`} accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
        </div>)
    }

    for (let i = 0; i < countInputs; i++)
        InputItems.push(createFileInputElement(i + 1))


    const GalleryItems = images.map((img) => {
        return (<div className="form__item">
            <div className="formWrap">
                <div>
                    <div className="form__item">
                        <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${img.path}`} alt={`${img.name}`} />
                    </div>
                </div>
                <div>
                    <div className="form__item">
                        <div className="btn btn--red btn--fill" id="" onClick={() => { removeImage(img._id) }}>Remove img</div>
                    </div>
                </div>
            </div>
        </div>)
    });

    const addMore = () => {
        if (countInputs + images.length >= 5) {
            alert("Each partner is limted to 5 images for now.")
            return
        }
        setCountInputs(countInputs + 1);
    }

    const removeImage = (_id) => {
        handleChange(images.filter(img => { if (img._id !== _id) return img }))
    }




    return (<div className="form__item">
        <div className="form__field-title">Gallery</div>
        <div className="form__field">
            {GalleryItems}
            {InputItems}
            <div className="form__item">
                <div className="btn btn--green btn--fill" onClick={addMore}>Add more images</div>
            </div>
        </div>
    </div>)
}



const VideoForm = ({ video, uploadFile, handleChange, handleUploadChange }) => {

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        handleUploadChange({ uploadVideoFile: file })

    }
    const removeVideo = (_id) => {
        handleChange(null)
    }


    return (<div className="form__item">
        <div className="form__field-title">Section video</div>
        {video ? <div className="formWrap">
            <div className="form__item">
                <video width="300" height="200" controls>
                    <source src={`https://assets.iqmpass.com/${video.path}`} type="video/mp4" />
                </video>
            </div>
            <div className="form__item">
                <div className="btn btn--red btn--fill" onClick={() => { removeVideo() }} >Remove</div>
            </div>
        </div>
            : <div className="form__field">
                <div className="form__item">
                    <label for="deal-video">Choose a video</label>
                    <input className="deal-video" type="file" name="deal-video" accept="video/*" onChange={handleVideoChange} />
                </div>
            </div>}
    </div>)

}

