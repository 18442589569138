import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Logo from "./iqm-logo.png";

import Dashboard from './Dashboard';
import Loading from './components/Loading'



import API from './network'


export default (props) => {

    const {match} = props;
    const [user, setUser] = useState(null)

    const [loading, setLoading] = useState(true)

    
    useEffect(()=>{
        setLoading(true)
        API.admin.authorise().then((user)=>{
            setUser(user)
            setLoading(false);
        }).catch(error=>console.log(error))

    },[])

     
    const handleLogout = () => {
      API.admin.logout();
      setUser(null)
    }

    if(loading) return <Loading fullScreen={true} />

    return(<Switch>
            <Route path={`${match.path}/login`}  render = {(props) => {
              if(!user)
                return <LoginForm {...props} handleLogin = {setUser} />
              else
                return <Redirect to={`${match.path}`} />
            }}/>

            <PrivateRoute path={`${match.path}`} user={user} handleLogout={handleLogout} component={Dashboard} />
    </Switch>)
}






const LoginForm = (props) => {

  const [loginData, setLoginData] = useState({email: '', password: ''})

  const handleChange = (event) => {
    const {name, value} = event.target
    setLoginData({...loginData, [name]: value})
  }
  const handleSubmit  = async (event)=>{
    event.preventDefault();
    try {
        const user = await API.admin.login({
          email:loginData.email,
          password:loginData.password
        });
        props.handleLogin(user);

    } catch (error) {
        console.log(error);
    }
  }
  return (
    <div className="modal section" id="signin">
      <div className="modalWrap">
        <div className="modalBox-wrap">
          <a className="modalBox__logo" href="index">
            <img src={Logo} alt="IQM Destination" />
          </a>
          <div className="modalBox">
            <div className="modalBox__form">
              <form className="form form--signin"  onSubmit={handleSubmit}>
                {/* <div className="form__message"><span>Invalid login, please try again</span>
                  <button className="form__close">
                    <svg width="15" height="16" viewbox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.731 2l12 12m-12 0l12-12" stroke="#1F0330" stroke-width="3.064" fill="none"
                        fill-rule="evenodd"></path>
                    </svg>
                  </button>
                </div> */}
                <div className="form__title">Sign in</div>
                <div className="form__item">
                  <div className="form__icon form__icon--email"></div>
                  <input className="email" type="email" name="email" placeholder="Email" required="required" onChange={handleChange} />
                  {/* <div className="form__error">Invalid email</div> */}
                </div>
                <div className="form__item">
                  <div className="form__icon form__icon--password"></div>
                  <input className="password" type="password" name="password" placeholder="Password"
                    required="required" onChange={handleChange}/>
                  {/* <div className="form__error">Invalid password</div> */}
                </div>
                <div className="form__item">
                  <input className="btn btn--blue btn--fill" id="signinBtn"  value="Sign in" type="submit"  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}




const PrivateRoute = ({ component: Component, user, ...rest }) => {
    return (
      <Route {...rest} render={(props) => {
          if(user) return <Component  user = {user} {...rest} {...props}  />
          else return <Redirect to={{ pathname: `${props.match.url}/login`, state: { from: props.location } }}/>
      }
    }/>);
}

      
    