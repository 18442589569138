import admin from './admin'
import destination from './destination'
import partner from './partner'
import deal from './deal'
import order from './order'
import pass from './pass'
import widget from './widget'
import notification from './notification'

import giftcard from './giftcard'
import itinerary from './itinerary'
import tripPlanner from './tripPlanner'




export default {
    admin,
    destination,
    partner,
    deal,
    order,
    pass,
    widget,
    notification,
    giftcard,
    itinerary,
    tripPlanner
}