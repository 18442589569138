/* IQM-PASS API Specification */
let url = ''
if (process.env.NODE_ENV == 'development')
    url = "http://localhost:3000"
else if (process.env.NODE_ENV == 'production')
    url = "https://api.iqmpass.com"

url = "https://api.iqmpass.com"


const API_URL = url;
const SERVICE_ROUTE = "/api/admin";

const getToken = () => {
    return localStorage.getItem('admin_token');
}

const setToken = (token) => {
    return localStorage.setItem('admin_token', token);
}

const POST_OPTS = ContentType => {
    return {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}


const POST_OPTS_M = ContentType => {
    return {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${getToken()}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }
}

export default {
    API_URL,
    SERVICE_ROUTE,
    setToken,
    getToken,
    POST_OPTS,
    POST_OPTS_M

}
