import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig



/* **************************************************************************************
 *                                  WIDGETS
 ************************************************************************************** */
/*
    Return [widget] where destinationID 
*/
const getWidgets = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getWidgets`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
    Return {widget} where widgetID and destinationID 
*/
const getWidget = async (destinationID, widgetID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!widgetID) throw new Error('widgetID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, widgetID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getWidget`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
*/
const createWidget = async (destinationID, payload) => {


    delete payload._id

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, payload }),
            ...POST_OPTS()
        }
        const pass = await fetch(`${API_URL}${SERVICE_ROUTE}/createWidget`, options);
        return await pass.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
*/
const updateWidget = async (destinationID, widgetID, payload) => {

    if (!destinationID) throw new Error('destinationID is required required')
    if (!widgetID) throw new Error('widgetID is required required')

    try {
        console.log(JSON.stringify({ destinationID, widgetID, payload }))
        const options = {
            body: JSON.stringify({ destinationID, widgetID, payload }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updateWidget`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}

/*
*/
const deleteWidget = async (destinationID, widgetID) => {

    if (!widgetID) throw new Error('widgetID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, widgetID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deleteWidget`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }

}







export default {
    getWidget,
    getWidgets,
    createWidget,
    updateWidget,
    deleteWidget
}