import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";


import OrderItem from './OrderItem'
import OrderList from './OrderList'

export default (props) => {
    const {match, user} = props;


    return(<Switch>
        <Route exact path={`${match.path}/`} render = {(props)=> <OrderList user={user} {...props }/> }></Route> 
        <Route path={`${match.path}/:orderID`} render = {(props)=> <OrderItem  user={user} {...props}/> }></Route>
    </Switch>)
}

