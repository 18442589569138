import React, { Component, Fragment, useState, useEffect } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import Loading from '../../components/Loading'
import API from '../../network'



export default (props) => {

    const {match, history} = props;
    const  {destinationID} = match.params

    const [deals, setDeals] = useState([])
    const [partners, setPartners] = useState([])
    const [fetching, setFetching] = useState(false)

    useEffect(()=>{
        setFetching(true)
        API.partner.getPartners(destinationID).then((partners)=>{
            setPartners(partners)

            API.deal.getDeals(destinationID).then(deals => {
                console.log(deals)
                setDeals(deals)
                setFetching(false)
            }).catch(error=>console.log(error))

            
        }).catch(error=>console.log(error))
    },[])


    const PartnerItems = partners.map((partner)=>{

        const dealCount = deals.filter(deal => deal.partnerID === partner._id).length

        return( 
            <tr key ={partner._id} className="partner-link" onClick = {()=>history.push(`${match.url}/${partner._id}`)} >
           
                <td>{partner.slug}</td>
                <td>{partner.name}</td>
                <td>{partner.email}</td>
                <td>{dealCount}</td>
                
            </tr>)
        })
  
    if(fetching) return <Loading />
    return (
        <div className="dashboard section">
            <div className="main show" id="partners">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        {/* <Search /> */}

                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--green" id="partnerNew"> <Link to={`${match.url}/newPartner`} >Add New IQM Partner</Link></div>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                                <tr className="partner-link" data-href="url:/">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>E-mail</th>
                                    <th>Deal count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PartnerItems}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>)
}




