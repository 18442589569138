import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig


/* **************************************************************************************
 *                                  PASS
 ************************************************************************************** */
/*
    Return [pass] where destinationID 
*/
const getPasses = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPasses`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }
}

/*
    Return {pass} where passID and destinationID 
*/
const getPass = async (destinationID, passID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!passID) throw new Error('passID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, passID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getPass`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }
}
/*
*/
const createPass = async (destinationID, payload) => {


    delete payload._id

    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, payload }),
            ...POST_OPTS()
        }
        const pass = await fetch(`${API_URL}${SERVICE_ROUTE}/createPass`, options);
        return await pass.json();
    } catch (error) {

        console.error(error);
        return error;
    }
}

/*
*/
const updatePass = async (destinationID, passID, payload) => {

    if (!destinationID) throw new Error('destinationID is required required')
    if (!passID) throw new Error('passID is required required')

    try {
        console.log(JSON.stringify({ destinationID, passID, payload }))
        const options = {
            body: JSON.stringify({ destinationID, passID, payload }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/updatePass`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }

}

/*
*/
const deletePass = async (destinationID, passID) => {

    if (!passID) throw new Error('passID is required required')
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, passID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/deletePass`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return error;
    }

}





export default {
    getPass,
    getPasses,
    createPass,
    updatePass,
    deletePass
}