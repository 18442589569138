import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Loading from '../../components/Loading'
import API from '../../network'


export default (props) => {

  const { match, history, user } = props;
  const { destinationID } = match.params;


  const [itineraryList, setItineraryList] = useState([])

  
  const [passList, setPassList] = useState([])
  const [dealList, setDealsList] = useState([])
  const [destination, setDestination] = useState(null)
  
  const [fetching, setFetching] = useState(true)


  const LoadData = async()=>{
    setFetching(true)
    const itineraryList = await API.itinerary.getItineraryList(destinationID);
    const passList = await API.pass.getPasses(destinationID)
    const destination = await API.destination.getDestination(destinationID)

    setItineraryList(itineraryList)
    setPassList(passList)
    setDestination(destination)
    setFetching(false)
  }

  useEffect(() => {
      try {
        LoadData()
      } catch (error) {
        console.log(error)
      }
  }, [])

  



  
  if (fetching) return <Loading />


  const ItineraryItems = itineraryList.map((itinerary) => {

    const pass = passList.find(pass => itinerary.passID ===  pass._id)
    const previewUrl = `https://iqmpass.com/hr/${destination.webUrl}/itinerary/${itinerary._id}`;

    return (
      <tr className="itinerary-link">
        <td onClick={() => history.push(`${match.url}/${itinerary._id}`)}>{itinerary.slug}</td>
        <td onClick={() => history.push(`${match.url}/${itinerary._id}`)}>{itinerary.name}</td>
        {pass ? <td onClick={() => history.push(`${match.url}/${itinerary._id}`)}>{pass.name}</td>: <td></td>}
        <td onClick={() => history.push(`${match.url}/${itinerary._id}`)}>{itinerary.deals.length}</td>
        {/* <td><a className="btn btn--green btn--fill" href={`https://iqmpass.com/itinerary/${itinerary._id}`} target="blank">Preview</a></td> */}
        <td><a className="btn btn--green btn--fill" href={previewUrl} target="blank">Preview</a></td>
      </tr>
    )

  })

  return (
    <div className="dashboard section">
      <div className="main show" id="pass">
        <div className="dashboard__main">
          <div className="dashboard__row">
            {/* <Search /> */}

            <div className="dashboard__btn">
              <div className="btn btn--fill btn--green" id="itinerary"><Link to={`${match.url}/newItinerary`}>Add New Itinerary</Link></div>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <thead>
                <tr className="pass-link" data-href="url:/">
                  <th>ID</th>
                  <th>Name</th>
                  <th>IQM Pass</th>
                  <th>Nr.deals</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody>
                {ItineraryItems}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}