import React, { useState, useEffect, Fragment } from 'react';

import Loading from '../../components/Loading'

import API from '../../network'
import toSlug from '../../helpers/toSlug'
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import RichTextEditor from 'react-rte';


export default (props) => {


    const { match, history } = props;
    const { destinationID, itineraryID } = match.params;


    const [name, setName] = useState("")

    const [deals, setDeals] = useState([])
    const [coverImage, setCoverImage] = useState(null)
    const [uploadCoverImage, setUploadCoverImage] = useState(null)
    const [passID, setPassID] = useState("")
    const [metaData, setMetaData] = useState({
        "hr": {
            "title": "",
            "subtitle": "",
            "description": "",
            "shortdesc": ""
        },
        "en": {
            "title": "",
            "subtitle": "",
            "description": "",
            "shortdesc": ""
        },
    })


    const [passList, setPassList] = useState([])
    const [dealList, setDealList] = useState([])
    const [partnerList, setPartnerList] = useState([])

    const [destination, setDestination] = useState(null)
    const [filter, setFilter] = useState('all')
    const [fetching, setFetching] = useState(true)


    const LoadData = async () => {
        setFetching(true)

        console.log("Fetching ...")



        const dealList = await API.deal.getActiveDeals(destinationID)
        const passList = await API.pass.getPasses(destinationID)
        const partnerList = await API.partner.getPartners(destinationID)
        const destination = await API.destination.getDestination(destinationID)
        setPassList(passList)
        setDealList(dealList)
        setPartnerList(partnerList)
        setDestination(destination)



        if (itineraryID) {
            const itinerary = await API.itinerary.getItinerary(destinationID, itineraryID)
            if (itinerary._id) {
                setName(itinerary.name)
                setCoverImage(itinerary.coverImage)
                setPassID(itinerary.passID)
                setMetaData({ ...metaData, ...itinerary.metaData })
                setDeals(dealList.filter(deal => itinerary.deals.includes(deal._id)))
                console.log(dealList.filter(deal => itinerary.deals.includes(deal._id)))
            } else {
                console.error(itinerary)
            }
        }




        setFetching(false)
    }

    useEffect(() => {
        try {
            LoadData()
        } catch (error) {
            console.log(error)
        }
    }, [])




    const handleSubmit = async () => {
        setFetching(true)

        const itinerary = {
            name,
            slug: toSlug(name),
            coverImage,
            metaData,
            deals,
            destinationID,
            passID
        }

        if (itineraryID) {
            const responseJSON = await API.itinerary.updateItinerary(destinationID, itineraryID, { ...itinerary, uploadCoverImage })
            console.log(responseJSON)
            alert("Save success")
            if (responseJSON._id) history.go()
        }
        else {
            const responseJSON = await API.itinerary.createItinerary(destinationID, { ...itinerary, uploadCoverImage })
            if (responseJSON._id) {
                const { url } = match
                history.push(url.replace("/newItinerary", ""))
            }
        }
        setFetching(false)
    }


    const handleDelete = async () => {
        setFetching(true)
        const { destinationID, itineraryID } = match.params
        // Todo: Handle with dialogg
        const res = await API.itinerary.deleteItinerary(destinationID, itineraryID)

        if (res.ok === 1) {
            const { url } = match
            history.push(url.replace(`/${itineraryID}`, ""))
        } else {
            
        }

        setFetching(false)
    }



    const addDeal = (deal) => {
        setDeals([deal, ...deals])
    }

    const removeDeal = (deal) => {
        const index = deals.indexOf(deal)
        if (index > -1) deals.splice(index, 1)
        setDeals([...deals])
    }




    if (fetching) return <Loading />

    const previewUrl = `https://iqmpass.com/hr/${destination.webUrl}/itinerary/${itineraryID}`;

    return (
        <div className="dashboard section">
            <div className="main show" id="itineraryItem">
                <div className="dashboard__main">
                    <div className="dashboard__row">
                        <div className="dashboard__btn">
                            <div className="btn btn--fill btn--yellow" id="itineraryBack" onClick={() => props.history.goBack()}>Back</div>
                        </div>
                        {itineraryID ? <a className="btn btn--fill btn--green" href={previewUrl} target="blank">Preview</a> : <div className="dashboard__title"> Itinerary Create</div>}
                    </div>

                    <div className="form-wrap">
                        <form className="form">
                            <div className="form__item">
                                <label htmlFor="name">Name*</label>
                                <input className="name" type="text" name="slug" placeholder="Enter deal name" required="required" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                            <PassSelectComponent itineraryID={itineraryID} passID={passID} passList={passList} handleChange={setPassID} />

                            <CoverImageForm coverImg={coverImage} uploadCoverImage={uploadCoverImage} handleUploadChange={setUploadCoverImage} handleChange={setCoverImage} />

                            <MetaDataForm metaData={metaData} lang="hr" handleChange={setMetaData} />

                            <MetaDataForm metaData={metaData} lang="en" handleChange={setMetaData} />


                            <Filter itineraryDeals={deals} dealList={dealList} filter={filter} handleChange={setFilter}/>
                            <div className="form__item">
                                <div className="formWrap">
                                    <div className="form__item">
                                        <ActiveDealList dealList={dealList} partners={partnerList} itineraryDeals={deals} add={addDeal} filter={filter}/>
                                    </div>
                                    <div className="form__item">
                                        <ItineraryDeals deals={deals} partners={partnerList} remove={removeDeal} />
                                    </div>
                                </div>
                            </div>

                            <div className="form__item form__item--mb50">
                                <div className="button-wrap">
                                    <div className="btn btn--red btn--fill" id="dealDelete" onClick={handleDelete}>Delete Deal</div>
                                    <input className="btn btn--green btn--fill" value="Save" onClick={handleSubmit} />
                                </div>
                            </div>


                        </form>
                    </div>



                </div>
            </div>
        </div>
    );
}





// Done !
const PassSelectComponent = ({ passID, passList, handleChange }) => {

    const Options = passList.map(pass => <option key={pass._id} value={pass._id}>{`${pass.name} (${pass.passType} ${pass.duration})`}</option>)
    const optionChanged = e => {
        console.log(e.target.value)
        const passID = passList.find(pass => pass._id === e.target.value)._id
        handleChange(passID)
    }

    return (<div className="form__item">
        <label htmlFor="partner-list">IQM PASS</label>
        <select name="partner-list" required="required" value={passID} onChange={optionChanged} >
            <option value="null" >Select IQM PASS</option>
            {Options}
        </select>
    </div>)

}




// TODO
const MetaDataForm = ({ handleChange, metaData, lang }) => {

    console.log(lang, metaData[lang])
    const inputChange = (event) => {
        const { name, value } = event.target

        handleChange({ ...metaData, [lang]: { ...metaData[lang], [name]: value } })
    }

    const descriptionChange = (value) => {
        handleChange({ ...metaData, [lang]: { ...metaData[lang], description: value.toString('html') } })
        setEditorState(value)
    }

    let description
    if (metaData[lang].description) {
        description = RichTextEditor.createValueFromString(metaData[lang].description.toString(), 'html')
    } else {
        description = RichTextEditor.createValueFromString("", 'html')
    }

    const [editorState, setEditorState] = React.useState(description);



    return (<div className="form__item">
        <div className="form__field-title">{`Web Data (${lang.toUpperCase()})`}</div>
        <div className="form__field">


            <div className="form__item">
                <label htmlFor="title">Title</label>
                <input className="title" type="text" name="title" value={metaData[lang].title} placeholder="Enter title" required="required" onChange={inputChange} />
            </div>

            <div className="form__item">
                <label htmlFor="subtitle">Subtitle</label>
                <input className="subtitle" type="text" name="subtitle" value={metaData[lang].subtitle} placeholder="Enter subtitle" required="required" onChange={inputChange} />
            </div>

            <div className="form__item">
                <label htmlFor="shortdesc">Short description</label>
                <textarea className="shortdesc" type="text" name="shortdesc" value={metaData[lang].shortdesc} placeholder="Enter shortdesc" required="required" onChange={inputChange} />
            </div>

            <div className="form__item" style={{ width: "100%" }}>
                <label htmlFor="description" style={{ color: "blue", fontSize: "15px" }}>Description</label>

                <RichTextEditor value={editorState} onChange={descriptionChange} style={{ width: "100%" }} />

            </div>
        </div>




    </div>)
}



const CoverImageForm = ({ coverImg, uploadFile, handleChange, handleUploadChange }) => {

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        handleUploadChange(file)

    }
    const removeImage = (_id) => {
        handleChange(null)
    }


    return (<div className="form__item">
        <div className="form__field-title">Cover image</div>
        {coverImg ? <div className="formWrap">
            <div className="form__item">
                <img style={{ maxWidth: '100%', width: "200px", height: 'auto' }} src={`https://assets.iqmpass.com/${coverImg.path}`} alt={`${coverImg.name}`} />
            </div>
            <div className="form__item">
                <div className="btn btn--red btn--fill" onClick={() => { removeImage() }} >Remove</div>
            </div>
        </div>
            : <div className="form__field">
                <div className="form__item">
                    <label htmlFor="gallery-img1">Choose image</label>
                    <input className="gallery-img1" type="file" name="cover-image" accept=".jpg, .jpeg, .png" onChange={handleImageChange} />
                </div>
            </div>}
    </div>)
}





const ItineraryDeals = ({ deals, partners, remove, }) => {

    const DealsItems = deals.map(deal => {

        const getPartnerName = (partners) => {
            const partner = partners.find(partner => partner._id === deal.partnerID)
            return partner ? partner.slug : ''
        }
        const categoriesList = (categories) => {
            let categoryString = ''
            for (let i = 0; i < categories.length; i++)
                categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
            return categoryString
        }

        const priceHRK = deal.price.find(p => p.currency === 'HRK')
        const priceEUR = deal.price.find(p => p.currency === 'EUR')

        return (<tr className="deal-link" data-href="url:/">
            {/* <td>{deal.slug}</td> */}
            <td>{deal.name}</td>
            <td>{getPartnerName(partners)}</td>
            <td>{categoriesList(deal.categories)}</td>
            <td>{`${deal.discount[0].value}%`}</td>
            <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
            <td>
                <div className="btn btn--red btn--fill" onClick={() => { remove(deal) }}>Remove</div>
            </td>
        </tr>)

    })

    return (
        <table className="table">
            <thead>
                <tr className="deal-link" data-href="url:/">
                    {/* <th>ID</th> */}
                    <th>Name</th>
                    <th>Partner Name</th>
                    <th>Type</th>
                    <th>Discount</th>
                    <th>Price (Eur/Hrk)</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>{DealsItems}</tbody>
        </table>)
}


const ActiveDealList = ({ dealList, itineraryDeals, partners, add, filter }) => {


    const DealItems = dealList.map(deal => {
        const getPartnerName = (partners) => {
            const partner = partners.find(partner => partner._id === deal.partnerID)
            return partner ? partner.slug : ''
        }
        const categoriesList = (categories) => {
            let categoryString = ''
            for (let i = 0; i < categories.length; i++)
                categoryString += ((i + 1) === categories.length) ? categories[i] : categories[i] + '\\';
            return categoryString
        }

        const priceHRK = deal.price.find(p => p.currency === 'HRK')
        const priceEUR = deal.price.find(p => p.currency === 'EUR')

        const includes = itineraryDeals.includes(deal)
        const rowClass = 'deal-link' + (includes ? ' table--paused' : '')

        if (filter === 'all' || deal.categories.find(cat => cat === filter)) {
            return (<tr className={rowClass} data-href="url:/">
                {/* <td>{deal.slug}</td> */}
                <td>{deal.name}</td>
                <td>{getPartnerName(partners)}</td>
                <td>{categoriesList(deal.categories)}</td>
                <td>{`${deal.discount[0].value}%`}</td>
                <td>{`€${priceEUR.value} / ${priceHRK.value}kn`}</td>
                <td>
                    {(!includes) ? <div className="btn btn--green btn--fill" onClick={() => { add(deal) }}>Add to list</div> : null}
                </td>
            </tr>)
        }
        

    })
    return (<Fragment>
        <table className="table">
            <thead>
                <tr className="deal-link" data-href="url:/">
                    {/* <th>ID</th> */}
                    <th>Name</th>
                    <th>Partner Name</th>
                    <th>Type</th>
                    <th>Discount</th>
                    <th>Price (Eur/Hrk)</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {DealItems}
            </tbody>
        </table>
    </Fragment>

    )
}


const Filter = ({itineraryDeals,dealList, filter, handleChange} )=>{

    const categorySlugMapper = (category, lang) =>{
       const mapper =  {
            "food-drink": {
                "hr": "Hrana & Piće",
                "en": "Food & Drink"
            },
            "to-sleep": {
                "hr": "Noćenje",
                "en": "To sleep"
            },
            "national-parks": {
                "hr": "Nacionalni parkovi",
                "en": "National parks"
            },
            "unesco": {
                "hr": "Unesco",
                "en": "Unesco"
            },
            "thematic-tours": {
                "hr": "Tematske ture",
                "en": "Thematic tours"
            },
            "attractions": {
                "hr": "Atrakcije",
                "en": "Attractions"
            },
            "local-production": {
                "hr": "Lokalna proizvodnja",
                "en": "Local production"
            },
            "sport-adventure": {
                "hr": "Sport & Avantura",
                "en": "Sport & Adventure"
            },
            "entertainment": {
                "hr": "Zabava",
                "en": "Entertainment"
            },
            "health-spa": {
                "hr": "Zdravlje & Spa",
                "en": "Health & Spa"
            }
        }
    
        return mapper[category][lang]
    
    }
    const optionChanged = e => {
        handleChange(e.target.value)
    }

    return(<Fragment>
        <div className="form__field-title">{`Active Deals (${itineraryDeals.length}/${dealList.length})`}</div>
        <div className="form__item">
            <label htmlFor="partner-list">Categories</label>
            <select name="partner-list" required="required" value={filter} onChange={optionChanged}>
                <option value="all">All</option>
                <option value="food-drink">{categorySlugMapper("food-drink", "en")}</option>
                <option value="to-sleep">{categorySlugMapper("to-sleep", "en")}</option>
                <option value="national-parks">{categorySlugMapper("national-parks", "en")}</option>
                <option value="unesco">{categorySlugMapper("unesco", "en")}</option>
                <option value="thematic-tours">{categorySlugMapper("thematic-tours", "en")}</option>
                <option value="attractions">{categorySlugMapper("attractions", "en")}</option>
                <option value="local-production">{categorySlugMapper("local-production", "en")}</option>
                <option value="sport-adventure">{categorySlugMapper("sport-adventure", "en")}</option>
                <option value="entertainment">{categorySlugMapper("entertainment", "en")}</option>
                <option value="health-spa">{categorySlugMapper("health-spa", "en")}</option>
            </select>
        </div>
        </Fragment>
    )
}