import NetworkConfig from './networkConfig'
const  { API_URL, SERVICE_ROUTE, setToken, getToken, POST_OPTS, POST_OPTS_M } = NetworkConfig

/* **************************************************************************************
 *                                  ORDER
 ************************************************************************************** */
/*
    Return [order] where destinationID 
*/
const getOrders = async (destinationID) => {
    if (!destinationID) throw new Error('destinationID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getOrders`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
    Return {order} where orderID and destinationID 
*/
const getOrder = async (destinationID, orderID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!orderID) throw new Error('orderID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, orderID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getOrder`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}


/*
    Return [passcard] where orderID 
*/
const getOrderPasscard = async (destinationID, orderID) => {
    if (!destinationID) throw new Error('destinationID is required required')
    if (!orderID) throw new Error('orderID is required required')

    try {
        const options = {
            body: JSON.stringify({ destinationID, orderID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/getOrderPasscard`, options);
        return await response.json();
    } catch (error) {

        console.error(error);
        return null;
    }
}

/*
*/
const downloadPasscard = async(destinationID, passcardID) => {
    try {
        const options = {
            body: JSON.stringify({ destinationID, passcardID }),
            ...POST_OPTS()
        }
        const response = await fetch(`${API_URL}${SERVICE_ROUTE}/downloadPasscard`, options);
        const blob = await response.blob();
        let file = window.URL.createObjectURL(blob);
        window.open(file);
    } catch (error) {
        console.error(error);
        return null;
    }
}




export default {
    getOrder,
    getOrders,
    getOrderPasscard,
    downloadPasscard
}